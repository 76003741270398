import { ThemeProvider, Modal, Paper, Grid, Typography, Switch, TextField, Button, InputAdornment, Box, InputLabel, MenuItem, IconButton, FormControlLabel, Tooltip, Stack, Select } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Delete } from "@material-ui/icons";
import { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../redux/storeStates/store";
import { Store } from "../../redux/storeStates/StoreStates";
import defaultEntities from "../../utils/EntityUtils";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { theme } from "../common/Theme";
import { FormTypes, SchedulerRepetition, SchedulerAssessmentType } from "../../interfaces/ui/Enums";
import { v4 as RandomUUID } from "uuid";
import moment from "moment";
import Routes from "../../data/Routes";
import User from "../../interfaces/entities/User";

interface Props {
    formType: string,
    startDate: string,
    showForm: ((choice: boolean) => void),
    selectedEvent: any,
}

const modalStyle = makeStyles({
    root: {
        alignContent: "center",
        margin: "auto",
        marginLeft: "50%",
        display: "flex",
        flexWrap: "wrap",
    },
    wrapper: {
        padding: "14px"
    },
    header: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    content: {
        paddingTop: "18px",
        paddingBottom: "18px",
    },
    footer: {
    },
    gridList: {
        display: 'grid',
    }
})

export default function SchedulerForm(props: Props) {
    if (props.formType === FormTypes.DATE) {
        return DateForm(props);
    }
    else if (props.formType === FormTypes.EVENT) {
        return EventForm(props);
    }
    else return <></>;
}

export function DateForm(props: Props) {
    const classes = modalStyle(theme);
    const initialTime = "T01:00";
    const dateFormat = "YYYY-MM-DD hh:mm:ss";

    const [startDate, setStartDate] = useState(props.startDate + initialTime);
    const [endDate, setEndDate] = useState("");
    const [groupOrIndividual, setGroupOrIndividual] = useState<string>(SchedulerAssessmentType.GROUP);
    const [repetition, setRepetition] = useState<string>(SchedulerRepetition.WEEKLY);
    const [assessmentEvent, setAssessmentEvent] = useState();

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let now = moment(new Date());
        let next = moment(new Date(startDate));
        let end = moment(new Date(endDate));

        let newAssessmentEvent = groupOrIndividual === SchedulerAssessmentType.GROUP ?
            defaultEntities.defaultUnitAssessmentEvent :
            defaultEntities.defaultUserAssessmentEvent;

        newAssessmentEvent.submittedTimeStamp = now.format(dateFormat);
        let assessmentEventsToSave = [];

        switch (repetition) {
            case SchedulerRepetition.SINGLE:
                let eventToSave = { ...newAssessmentEvent };
                eventToSave.assessmentEventId = RandomUUID();
                eventToSave.plannedDate = next.format(dateFormat);
                assessmentEventsToSave.push(eventToSave);
                break;

            case SchedulerRepetition.WEEKLY:
                while (next.isBefore(end)) {
                    let eventToSave = { ...newAssessmentEvent };
                    eventToSave.assessmentEventId = RandomUUID();
                    eventToSave.plannedDate = next.format(dateFormat);
                    assessmentEventsToSave.push(eventToSave);

                    next.add(1, 'week');
                }
                break;

            case SchedulerRepetition.MONTHLY:
                while (next.isBefore(end)) {
                    let eventToSave = { ...newAssessmentEvent };
                    eventToSave.assessmentEventId = RandomUUID();
                    eventToSave.plannedDate = next.format(dateFormat);
                    assessmentEventsToSave.push(eventToSave);

                    next.add(1, 'month');
                }
                break;

            default:
                break;
        }

        if (groupOrIndividual === SchedulerAssessmentType.GROUP) {
            Routes.AssessmentEvent.Unit.add(assessmentEventsToSave);
        }

        if (groupOrIndividual === SchedulerAssessmentType.INDIVIDUAL) {
            let usersList: User[] = [];
            store.getState().units.map(unit => usersList = usersList.concat(unit.users));
            Routes.AssessmentEvent.User.add(assessmentEventsToSave);
        }

        props.showForm(false);
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open
                className={classes.root}
            >
                <Paper className={classes.wrapper} elevation={3}>

                    <Grid container spacing={2} justifyContent="center">
                        <Grid item className={classes.header}>
                            <Typography>{store.getState().translation.dictionary["newEvent"]}</Typography>
                        </Grid>
                    </Grid>

                    <form className={classes.content} onSubmit={(event) => submit(event)} >
                        <br />
                        <TextField
                            required
                            variant="outlined"
                            label={store.getState().translation.dictionary["startDate"]}
                            value={startDate}
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                                setStartDate(event.target.value);
                            }}
                        />
                        <br />
                        <br />
                        <TextField
                            required={!(repetition === SchedulerRepetition.SINGLE)}
                            variant="outlined"
                            label={store.getState().translation.dictionary["endDate"]}
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            disabled={repetition === SchedulerRepetition.SINGLE}
                            onChange={(event) => {
                                setEndDate(event.target.value);
                            }}
                        />
                        <br />
                        <br />
                        <Select
                            MenuProps={{
                                MenuListProps: {
                                className: classes.gridList, // Apply styles to the ul element
                                },
                            }} 
                            // required                            
                            // variant="outlined"
                            // select
                            label={store.getState().translation.dictionary["type"]}
                            value={groupOrIndividual}
                            onChange={(event:any) => { setGroupOrIndividual(event.target.value); }}
                            // helperText=""
                        >
                            <MenuItem value={SchedulerAssessmentType.GROUP}>{store.getState().translation.dictionary["group"]}</MenuItem>
                            <MenuItem value={SchedulerAssessmentType.INDIVIDUAL}>{store.getState().translation.dictionary["user"]}</MenuItem>
                        </Select>

                        <Select
                            MenuProps={{
                                MenuListProps: {
                                className: classes.gridList, // Apply styles to the ul element
                                },
                            }} 
                            // required
                            // variant="outlined"
                            // select
                            label={store.getState().translation.dictionary["repetition"]}
                            value={repetition}
                            onChange={(event) => {
                                setRepetition(event.target.value);
                            }}
                            // helperText=""
                        >
                            <MenuItem value={SchedulerRepetition.SINGLE}>{store.getState().translation.dictionary["single"]}</MenuItem>
                            <MenuItem value={SchedulerRepetition.WEEKLY}>{store.getState().translation.dictionary["weekly"]}</MenuItem>
                            <MenuItem value={SchedulerRepetition.MONTHLY}>{store.getState().translation.dictionary["monthly"]}</MenuItem>
                        </Select>
                        <br />

                        <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
                            <Button type="submit" variant="contained">{store.getState().translation.dictionary["save"]}</Button>

                            <Button onClick={() => {
                                props.showForm(false);
                            }} variant="contained">{store.getState().translation.dictionary["cancel"]}</Button>
                        </Stack>
                    </form>
                </Paper>
            </Modal>
        </ThemeProvider >
    )
}

export function EventForm(props: Props) {
    const classes = modalStyle(theme);
    const initialTime = "T07:00";
    const units = useSelector((state: Store) => state.units);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [repetition, setRepetition] = useState(SchedulerRepetition.WEEKLY);
    const [choosenUnit, setChoosenUnit] = useState({ ...units.filter(unit => unit.active === 1)[0] });
    const [wholeSequenceSelected, setWholeSequenceSelected] = useState(false);

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setShowConfirmationDialog(true);
    }

    function onDelete() {
        if (props.selectedEvent.hasOwnProperty("unitAssessment")) {
            if (wholeSequenceSelected) {
                Routes.AssessmentEvent.Unit.deleteSequence(props.selectedEvent);
            } else {
                Routes.AssessmentEvent.Unit.delete(props.selectedEvent);
            }
        } else if (props.selectedEvent.hasOwnProperty("userAssessment")) {
            if (wholeSequenceSelected) {
                Routes.AssessmentEvent.User.deleteSequence(props.selectedEvent);
            } else {
                Routes.AssessmentEvent.User.delete(props.selectedEvent);
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Stack>
                <Modal
                    open
                    className={classes.root}
                >
                    <Paper className={classes.wrapper} elevation={3}>

                        <form className={classes.content} onSubmit={(event) => submit(event)}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item className={classes.header}>
                                    <Typography>{store.getState().translation.dictionary["event"]}</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={store.getState().translation.dictionary["remove"]}>
                                        <IconButton
                                            color="primary"
                                            aria-label={store.getState().translation.dictionary["remove"]}
                                            type="submit"
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                    {/* TODO */}
                                    {/* <IconButton
                                color="primary"
                                aria-label="Redigera"
                                // className={classes.iconButton}
                                onClick={() => { setShowConfirmationDialog(true) }}
                            >
                                <Edit />
                            </IconButton> */}
                                </Grid>
                            </Grid>

                            <div className={classes.content}>
                                <FormControlLabel control={
                                    <Switch
                                        onChange={() => setWholeSequenceSelected(!wholeSequenceSelected)}
                                    />
                                }
                                    label={store.getState().translation.dictionary["chooseEntireSequence"]} />
                                <br />
                                <br />
                                <TextField
                                    required
                                    variant="outlined"
                                    label={store.getState().translation.dictionary["plannedDate"]}
                                    value={props.selectedEvent.plannedDate}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                />
                                <br />
                            </div>
                            <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
                                <Button onClick={() => {
                                    props.showForm(false);
                                }} variant="contained">{store.getState().translation.dictionary["return"]}</Button>
                            </Stack>

                        </form>
                        <ConfirmationDialog
                            show={showConfirmationDialog}
                            label={store.getState().translation.dictionary["remove"]}
                            text={store.getState().translation.dictionary["confirm"]}
                            yesButtonLabel={store.getState().translation.dictionary["yes"]}
                            noButtonLabel={store.getState().translation.dictionary["no"]}
                            onChoice={(choice) => {
                                if (choice)
                                    onDelete();

                                setShowConfirmationDialog(false);
                            }}
                        />
                    </Paper>
                </Modal>
            </Stack>
        </ThemeProvider >
    )
}