import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close"
import * as Category from "./Category";
import * as Timespan from "./Timespan";
import * as Priority from "./Priority";
import React, { useState } from "react";
import { ActionEditArea } from "./ActionEditArea";
import { Action } from "../../../interfaces/ui/Action";
import { useSelector } from "react-redux";
import { Store } from "../../../redux/storeStates/StoreStates";
import { UnitAssessmentEvent } from "../../../interfaces/entities/UnitAssessmentEvent";
import Unit from "../../../interfaces/entities/Unit";
import { idText } from "typescript";
import * as CommentType from "./CommentType";
import UnitAssessment from "../../../interfaces/entities/UnitAssessment";
import UserAssessment from "../../../interfaces/entities/UserAssessment";
import UnitAssessmentExt from "../../../interfaces/entities/UnitAssessmentExt";
import UserAssessmentExt from "../../../interfaces/entities/UserAssessmentExt";
import { store } from '../../../redux/storeStates/store';

interface Props {
    searchString: string;
    categoryFilter: number;
    commentTypeFilter: number;
    timespanFilter: number;
    priorityFilter: number;
    sortBy: string;
    sinceFilter: Date | null;
    toFilter: Date | null;
}

export const ActionList: React.FC<Props> = ({ searchString, categoryFilter, commentTypeFilter, timespanFilter, priorityFilter, sortBy, sinceFilter, toFilter }) => {

    const checkboxFilter = useSelector((state: Store) => state.dashboardCheckboxFilter);

    const unitAssessments = useSelector((state: Store) => state.units.map(u=>u.unitAssessmentEvents.filter(uae => uae.unitAssessment !== null && uae.unitAssessment !== undefined).map(uae=>({...uae, unit: u})).map(a=>({ prognosisLongtermComment: a.unitAssessment.prognosisLongtermComment, prognosisShorttermComment: a.unitAssessment.prognosisShorttermComment, actionsLongterm: a.unitAssessment.actionsLongterm, actionsShortterm: a.unitAssessment.actionsShortterm, unitImpactAssessmentValues: a.unitAssessment.unitImpactAssessmentValues, unitId: a.unit.unitId, submissionDate: a.plannedDate }) as UnitAssessmentExt)).flat());
    const userAssessments = useSelector((state: Store) => state.users.map(u=>u.userAssessmentEvents.filter(uae => uae.userAssessment !== null && uae.userAssessment !== undefined).map(uae=>({...uae, user: u})).map(a=>({prognosisLongtermComment: a.userAssessment.prognosisLongtermComment, prognosisShorttermComment: a.userAssessment.prognosisShorttermComment, userImpactAssessmentValues: a.userAssessment.userImpactAssessmentValues, unitId: a.user.unit.id, submissionDate: a.plannedDate}) as UserAssessmentExt)).flat());

    const [accordionsExpanded, setAccordionsExpanded] = useState(new Array<number>());
    const units = useSelector((state: Store) => state.units);

    const getImpactfactorsUnit = function(unitAssessment:UnitAssessment, unitId: string) {
        let result = unitAssessment.unitImpactAssessmentValues.map(i=>({unitId: unitId, id: unitAssessment.assessmentId, type: CommentType.IMPACT_FACTOR, creationDate: new Date(unitAssessment.submissionDate), actionText: i.impactFactor.name + ": " + i.comment}) as Action)
        return result;
    }
    const getImpactfactorsUser = function(userAssessment:UserAssessment, unitId: string) {
        let result = userAssessment.userImpactAssessmentValues.map(i=>({unitId: unitId, id: userAssessment.assessmentId, type: CommentType.IMPACT_FACTOR, creationDate: new Date(userAssessment.submissionDate), actionText: i.impactFactor.name + ": " + i.comment}) as Action)
        return result;
    }

        const [actionsArray, setActionsArray] = useState([
            ...unitAssessments.filter(a=>a).filter(a=>a.prognosisLongtermComment).map(a=>({unitId: a.unitId, id: a.assessmentId, type: CommentType.LONGTERM, creationDate: new Date(a.submissionDate), actionText: a.prognosisLongtermComment}) as Action),
            ...unitAssessments.filter(a=>a).filter(a=>a.prognosisShorttermComment).map(a=>({unitId: a.unitId, id: a.assessmentId, type: CommentType.SHORTTERM, creationDate: new Date(a.submissionDate), actionText: a.prognosisShorttermComment}) as Action),
            ...unitAssessments.filter(a=>a).filter(a=>a.actionsLongterm).map(a=>({unitId: a.unitId, id: a.assessmentId, type: CommentType.ACTION_LONGTERM, creationDate: new Date(a.submissionDate), actionText: a.actionsLongterm}) as Action),
            ...unitAssessments.filter(a=>a).filter(a=>a.actionsShortterm).map(a=>({unitId: a.unitId, id: a.assessmentId, type: CommentType.ACTION_SHORTTERM, creationDate: new Date(a.submissionDate), actionText: a.actionsShortterm}) as Action),
            ...unitAssessments.filter(a=>a).filter(a=>a.unitImpactAssessmentValues).map(a=>getImpactfactorsUnit(a, a.unitId)).flat(),
            ...userAssessments.filter(a=>a).filter(a=>a.prognosisLongtermComment).map(a=>({unitId: a.unitId, id: a.assessmentId, type: CommentType.LONGTERM, creationDate: new Date(a.submissionDate), actionText: a.prognosisLongtermComment}) as Action),
            ...userAssessments.filter(a=>a).filter(a=>a.prognosisShorttermComment).map(a=>({unitId: a.unitId, id: a.assessmentId, type: CommentType.SHORTTERM, creationDate: new Date(a.submissionDate), actionText: a.prognosisShorttermComment}) as Action),
            ...userAssessments.filter(a=>a).filter(a=>a.userImpactAssessmentValues).map(a=>getImpactfactorsUser(a, a.unitId)).flat(),
        ]
        );

        const filteredActions = actionsArray.filter(action => (action.actionText !== null) && action.actionText.toLowerCase().includes(searchString))
                                        .filter(action => commentTypeFilter === -1 || action.type === commentTypeFilter)
                                        .filter(action => (toFilter === null || sinceFilter === null) || (action.creationDate <= toFilter && action.creationDate >= sinceFilter))
                                        .filter(action => checkboxFilter.SelectedIds.includes(action.unitId));

    switch (sortBy) {
        case "priorityHigh":
        case "priorityLow":
            filteredActions.sort((a: Action, b: Action) => {
                if (a["priority"] < b["priority"])
                    return 1;
                else if (a["priority"] > b["priority"])
                    return -1;
                else
                    return 0;
            })
            break;
        case "newest":
        case "oldest":
            filteredActions.sort((a: Action, b: Action) => {
                if (a["creationDate"] < b["creationDate"])
                    return 1;
                else if (a["creationDate"] > b["creationDate"])
                    return -1;
                else
                    return 0;
            })
            break;
    }
    if (sortBy === "priorityLow" || sortBy === "oldest")
        filteredActions.reverse();

    const calculateTime = function (date: Date) {
        let seconds = (new Date().getTime() - date.getTime()) / 1000;
        let interval = seconds / 31556952;
        if (interval >= 1)
            //return Math.floor(interval) + (interval >= 2 ? " år" : " år") + " sedan";
            return Math.floor(interval) + (interval >= 2 ? " " + store.getState().translation.dictionary["years"] : " " + store.getState().translation.dictionary["year"]) + " " + store.getState().translation.dictionary["since"];
        interval = seconds / 2592000;
        if (interval >= 1)
            //return Math.floor(interval) + (interval >= 2 ? " månader" : " månad") + " sedan";
            return Math.floor(interval) + (interval >= 2 ? " " + store.getState().translation.dictionary["months"] : " " + store.getState().translation.dictionary["month"]) + " " + store.getState().translation.dictionary['since'];
        interval = seconds / 86400;
        if (interval >= 2)
            //return Math.floor(interval) + (interval >= 2 ? " dagar" : " dag") + " sedan";
            return Math.floor(interval) + (interval >= 2 ? " " + store.getState().translation.dictionary["days"] : " " + store.getState().translation.dictionary["day"]) + " " + store.getState().translation.dictionary["since"];

        //return (date.getDate() < new Date().getDate() ? "Igår " : "Idag ") + date.getHours() + ":" + date.getMinutes();
        return (date.getDate() < new Date().getDate() ? store.getState().translation.dictionary["yesterday"] + " " : store.getState().translation.dictionary["today"]  + " ") + date.getHours() + ":" + date.getMinutes();
    }

    const updateAction = function (action: Action, accordionIndex: number) {
        let actionIndex;
        for (actionIndex = 0; actionIndex < filteredActions.length; actionIndex++) {
            if (filteredActions[actionIndex].id === action.id) {
                break;
            }
        }
        filteredActions[actionIndex] = action;
        let newActions: Action[] = actionsArray;
        for (let ii = 0; ii < newActions.length; ii++) {
            if (newActions[ii].id === action.id)
                newActions[ii] = action;
        }

        setActionsArray(newActions);
        handleExpanded(accordionIndex);
    }

    const handleExpanded = function (accordionIndex: number) {
        let expandedArray: number[] = accordionsExpanded;
        if (expandedArray.indexOf(accordionIndex) < 0)
            expandedArray.push(accordionIndex);
        else
            expandedArray = expandedArray.filter(i => i !== accordionIndex);

        setAccordionsExpanded([...expandedArray]);
    }

    return (
        <div style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
            {filteredActions.map((action, index) =>
                <Accordion
                    id={"Accordion_" + index}
                    key={index}
                    expanded={accordionsExpanded.indexOf(index) >= 0}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <AccordionSummary
                        expandIcon={
                            <div>
                                {
                                    accordionsExpanded.indexOf(index) < 0 ?
                                        <EditIcon onClick={() => handleExpanded(index)} style={{ pointerEvents: "auto" }} />
                                        :
                                        <CloseIcon onClick={() => handleExpanded(index)} style={{ pointerEvents: "auto" }} />
                                }
                            </div>
                        }
                        style={{ display: "flex", userSelect: "text", pointerEvents: "none" }}
                    >
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center", paddingRight: 16 }}>
                                {/* <Typography>{Priority.PRIORITY_STRINGS[action.priority]}</Typography> */}
                                <Typography>{Timespan.TIMESPAN_STRINGS[action.timespan]}</Typography>
                            </div>

                            <Divider orientation="vertical" flexItem style={{ display: "table-cell" }} />

                            <div style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: 16 }}>
                                <Typography>{action.actionText}</Typography>
                                <Typography variant="body1">{action.note}</Typography>
                                <Typography variant="body2" style={{ opacity: .88 }}>
                                    {calculateTime(action.creationDate)}
                                    {/* {calculateTime(action.creationDate)} | Kategori: {(action.categories.length > 0) ?
                                        action.categories.map((c, i) =>
                                            Category.CATEGORY_STRINGS[c] + (i < action.categories.length - 1 ? ", " : "")
                                        ) : "-"
                                    } */}
                                </Typography>
                            </div>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>
                        {/* <ActionEditArea action={action} onSave={(a: Action) => updateAction(a, index)} onClose={() => handleExpanded(index)} /> */}
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    );
}

export default ActionList;