import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import Organization from '../../interfaces/entities/Organization';
import DataInit from '../../data/DataInit';
import { store } from '../../redux/storeStates/store';
import { ActionTypes } from '../../redux/actionTypes';

// const organizations = [{organizationId: "8dd40ca5-29a6-4c1a-95b0-da6c10b67cf1", name: "DummyOrganization"} as Organization, {organizationId: "abbccca7-29a6-4c1a-95b0-da6c10b67cf1", name: "DummyOrganization2"} as Organization];

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: Organization;
  onClose: (value: Organization) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: Organization) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{store.getState().translation.dictionary["chooseOrganization"]}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {store.getState().availableOrganizations.map((organization) => (
          <ListItem button onClick={() => handleListItemClick(organization)} key={organization.organizationId}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={organization.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState(store.getState().availableOrganizations[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: Organization) => {
    setOpen(false);
    setSelectedValue(value);
    store.dispatch({ type: ActionTypes.SET_SELECTED_ORGANIZATION_ID, payload: value.organizationId });
  };

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
