import { config } from "process";
import { showSnackbar } from "../../components/common/SnackBar";
import Configuration from "../../interfaces/entities/Configuration";
import ConfigurationState from "../../interfaces/entities/ConfigurationState";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { store } from "../../redux/storeStates/store";
import { api, getConfiguration, getHeaders } from "./DataRepository";

export function addConfiguration(configuration: Configuration): Promise<Configuration> {
    let confState = {configurationId: configuration.configurationId, name: configuration.name, type: configuration.type, active: configuration.active, 
        impactFactorIds: [], impactFactorAlias:[], commentsActivated:[] } as ConfigurationState;
    configuration.impactFactorConfigurations.forEach(ic=>{
        ic.impactFactorConfigurationId = "";
        confState.impactFactorIds.push(ic.impactFactor.impactFactorId);
        confState.impactFactorAlias.push(ic.displayName);
        confState.commentsActivated.push(ic.commentVisible==1);
    }); // TODO - an UUID with wrong format i set somewhere in "FormEditView"
    
    return new Promise((resolve, reject) => {
        api.post(`configuration/add/organizationId/${store.getState().organization.organizationId}`, confState, getHeaders())
        // api.post(`configuration/add/organizationId/${store.getState().organization.organizationId}`, getConfiguration(configuration))
            .then(res => {
                resolve(res.data as Configuration);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

export function updateConfiguration(configuration: Configuration): Promise<Configuration> {
    let confState = {configurationId: configuration.configurationId, name: configuration.name, type: configuration.type, active: configuration.active, 
        impactFactorIds: [], impactFactorAlias:[], commentsActivated:[] } as ConfigurationState;
    configuration.impactFactorConfigurations.forEach(ic=>{
        ic.impactFactorConfigurationId = "";
        confState.impactFactorIds.push(ic.impactFactor.impactFactorId);
        confState.impactFactorAlias.push(ic.displayName);
        confState.commentsActivated.push(ic.commentVisible==1);
    }); // TODO - an UUID with wrong format i set somewhere in "FormEditView"

    return new Promise((resolve, reject) => {
        api.put(`configuration/update`, confState, getHeaders())
            .then(res => {
                resolve(res.data as Configuration);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

export function setActiveConfiguration(configuration: Configuration): Promise<Configuration> {
    return new Promise((resolve, reject) => {
        api.put(`configuration/set/active/configurationId/${configuration.configurationId}`, {}, getHeaders())
            .then(res => {
                store.getState().configurations.forEach((conf)=>{
                    if(conf.configurationId==configuration.configurationId) {
                        conf.active = 1;
                    } else {
                        conf.active = 0;
                    }
                });
                resolve(res.data as Configuration);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

export function deleteConfigurations(configuration: Configuration) {
    return new Promise((resolve, reject) => {
        api.delete(`configuration/delete/${configuration.configurationId}`, getConfiguration({}))
            .then(res => {
                resolve(res.data as String[]);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

const ConfigurationRepository = {
    add: addConfiguration,
    update: updateConfiguration,
    setActive: setActiveConfiguration,
    delete: deleteConfigurations,
}

export default ConfigurationRepository;