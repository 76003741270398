import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Divider } from "@mui/material"
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import { Omit } from '@material-ui/types';
import { useSelector } from 'react-redux';
import { Store } from '../redux/storeStates/StoreStates';
import { ActionTypes } from '../redux/actionTypes';
import { store } from '../redux/storeStates/store';
import { FormTypes, Views } from "../interfaces/ui/Enums";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishIcon from '@mui/icons-material/Publish';
import SummarizeIcon from '@mui/icons-material/Summarize';

const categories = [
    {
        id: 'organization',
        children: [
            { id: 'units', icon: <PeopleIcon /> },
            { id: 'users', icon: <DnsRoundedIcon /> },
        ],
    },
    {
        id: 'settings',
        children: [
            { id: 'schedule', icon: <SettingsIcon /> },
            { id: 'configuration', icon: <TimerIcon /> },
        ],
    },
    {
        id: 'analysis',
        children: [
            { id: 'textAnalysis', icon: <AnalyticsIcon /> },
            { id: 'reports', icon: <SummarizeIcon /> },
        ],
    },
    {
        id: 'importExport',
        children: [
            { id: 'import', icon: <PublishIcon /> },
            { id: 'export', icon: <FileDownloadIcon /> },
        ],
    },
];

const styles = (theme: Theme) =>
    createStyles({
        categoryHeader: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        categoryHeaderPrimary: {
            color: theme.palette.common.white,
        },
        item: {
            paddingTop: 1,
            paddingBottom: 1,
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
        },
        itemCategory: {
            backgroundColor: '#232f3e',
            boxShadow: '0 -1px 0 #404854 inset',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        firebase: {
            fontSize: 24,
            color: theme.palette.common.white,
        },
        itemActiveItem: {
            color: '#4fc3f7',
        },
        itemPrimary: {
            fontSize: 'inherit',
        },
        itemIcon: {
            minWidth: 'auto',
            marginRight: theme.spacing(2),
        },
        divider: {
            marginTop: theme.spacing(2),
        },
    });

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> { }

function Navigator(props: NavigatorProps) {
    const { classes, ...other } = props;
    const organizationName = useSelector((state: Store) => state.organization.name);
    const translation = useSelector((state: Store) => state.translation);

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    PWELT
                </ListItem>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    {organizationName}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        store.dispatch({ type: ActionTypes.SET_VIEW, payload: Views.DASHBOARD });
                    }}
                    className={clsx(classes.item, classes.itemCategory)}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.itemPrimary }} >
                        Dashboard
                    </ListItemText>
                </ListItem>
                {categories.map(({ id, children }) => (
                    <React.Fragment key={id}>
                        <ListItem className={classes.categoryHeader}>
                            <ListItemText classes={{ primary: classes.categoryHeaderPrimary }} >
                                {translation.dictionary[id]}
                            </ListItemText>
                        </ListItem>
                        {children.map(({ id: childId, icon }) => (
                            <ListItem
                                key={childId}
                                button
                                onClick={() => {
                                    store.dispatch({ type: ActionTypes.SET_VIEW, payload: childId as Views });
                                    store.dispatch({ type: ActionTypes.SET_SELECTED_USERS, payload: [] });
                                    store.dispatch({ type: ActionTypes.SET_SELECTED_UNITS, payload: [] });
                                }}
                                className={clsx(classes.item, classes.itemActiveItem)}
                            >
                                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.itemPrimary }} >
                                    {translation.dictionary[childId]}
                                </ListItemText>
                            </ListItem>
                        ))}
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}

export default withStyles(styles)(Navigator);