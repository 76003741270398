import { FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core";
import moment from "moment";
import FullCalendar, { EventClickArg } from '@fullcalendar/react'
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"
import dayGridPlugin from '@fullcalendar/daygrid'
import { useState } from "react";
import SchedulerForm from "./forms/SchedulerForm";
import { FormTypes } from "../interfaces/ui/Enums";
import { useSelector } from "react-redux";
import { Store } from "../redux/storeStates/StoreStates";
import AssessmentEvent from "../interfaces/entities/AssessmentEvent";
import { Button, Checkbox, Paper } from "@mui/material";
import defaultEntities from "../utils/EntityUtils";
import ConfirmationDialog from "./common/ConfirmationDialog";
import Routes from "../data/Routes";
import { store } from '../redux/storeStates/store';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface IEvent {
    id: string,
    title: string,
    start: string,
}

export default function AssessmentScheduler() {
    const units = useSelector((state: Store) => state.units);
    const translation = useSelector((state: Store) => state.translation);

    const [startDate, setStartDate] = useState("");
    const [dateClicked, setDateClicked] = useState(false);
    const [eventClicked, setEventClicked] = useState(false);
    const [showUnitAssessmentEvents, setShowUnitAssessmentEvents] = useState(true);
    const [showUserAssessmentEvents, setShowUserAssessmentEvents] = useState(true);
    const [selectedAssessmentEvent, setSelectedAssesmentEvent] = useState<AssessmentEvent>({ ...defaultEntities.defaultUserAssessmentEvent });

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [dialogConfirmed, setDialogConfirmed] = useState(false);

    const handleDateClick = (arg: DateClickArg) => {
        setStartDate(arg.dateStr);
        setDateClicked(!dateClicked);
    }

    const handleEventClick = (arg: EventClickArg) => {
        if (!(units.length > 0))
            return;

        let selectedAssessmentEvent =
            units.filter(unit => unit.active === 1)[0].unitAssessmentEvents.filter(ae => ae.assessmentEventId === arg.event.id)[0] ||
            units.filter(unit => unit.active === 1)[0].users[0].userAssessmentEvents.filter(ae => ae.assessmentEventId === arg.event.id)[0]

        setSelectedAssesmentEvent(selectedAssessmentEvent);
        setEventClicked(true);
    }

    function getEvents() {
        if (!(units.length > 0))
            return;

        let eventList: IEvent[] = [];
        let dates:string[] = [];

        // Get one event for each date
        if (showUnitAssessmentEvents) {
            let activeUnits = units.filter(unit => unit.active === 1);
            activeUnits.forEach(unit => {
                unit.unitAssessmentEvents.forEach(ae => {
                    if (!dates.includes(ae.plannedDate)) {
                        eventList.push({
                            id: ae.assessmentEventId,
                            title: translation.dictionary["unit"],
                            start: moment(ae.plannedDate).format("YYYY-MM-DD hh:mm:ss"),
                        })
                        dates.push(ae.plannedDate);    
                    }
                })
            });

            // units.filter(unit => unit.active === 1)[0].unitAssessmentEvents.forEach(ae => {
            //     eventList.push({
            //         id: ae.assessmentEventId,
            //         title: translation.dictionary["unit"],
            //         start: moment(ae.plannedDate).format("YYYY-MM-DD hh:mm:ss"),
            //     })
            // });
        }
        if (showUserAssessmentEvents) {
            // Get one event for each date
            let activeUsers = units.filter(unit => unit.active === 1).map(unit => unit.users).flat();
            activeUsers.forEach(user => {
                user.userAssessmentEvents.forEach(ae => {
                    if (!dates.includes(ae.plannedDate)) {
                        eventList.push({
                            id: ae.assessmentEventId,
                            title: translation.dictionary["user"],
                            start: moment(ae.plannedDate).format("YYYY-MM-DD hh:mm:ss"),
                        })
                        dates.push(ae.plannedDate);
                    }
                })
            });


            // let gotUserIndex = -1;
            // for (let index = 0; index < units.length - 1; index++) {
            //     if (units[index].users && units[index].users.length > 0) {
            //         gotUserIndex = index;
            //         break;
            //     }
            // }
            // if (gotUserIndex !== -1) {
            //     units.filter(unit => unit.active === 1)[0].users[0].userAssessmentEvents.forEach(ae => {
            //         eventList.push({
            //             id: ae.assessmentEventId,
            //             title: translation.dictionary["user"],
            //             start: moment(ae.plannedDate).format("YYYY-MM-DD hh:mm:ss"),
            //         })
            //     })
            // }
        }
        return eventList;
    }

    function getCompletedEventsStr(date: string, id: string) {
        if (!(units.length > 0))
            return;

        let totEvents = 0;
        let completetEvents = 0;

        if (units.find(unit => unit.unitAssessmentEvents.find(ae => ae.assessmentEventId === id))) {
            units.forEach((unit) => {
                let ev = unit.unitAssessmentEvents.filter(ev => ev.plannedDate === date);
                if (ev.length) {
                    if (ev[0].status === 1 && ev[0].emailStatus === 1) {
                        totEvents = totEvents + 1;
                        completetEvents = completetEvents + 1;
                    } else
                        totEvents = totEvents + 1;
                }
            })
        } else {
            units.forEach(unit => {
                unit.users.forEach(user => {
                    let ev = user.userAssessmentEvents.filter(ev => ev.plannedDate === date);
                    if (ev.length) {
                        if (ev[0].status === 1 && ev[0].emailStatus === 1) {
                            totEvents = totEvents + 1;
                            completetEvents = completetEvents + 1;
                        } else
                            totEvents = totEvents + 1;
                    }
                });
            })
        }

        return completetEvents + "/" + totEvents;
    }

    function customEventContent(eventInfo: any) {
        return (
            <Paper style={{ backgroundColor: "#2196f3", width: "100%", minHeight: "40px", padding: "4px", cursor: "pointer" }} variant="elevation" elevation={3}>

                <Typography style={{ color: "white" }} variant="body2" >
                    {eventInfo.event.title}
                </Typography>

                {/* Check how this works with live data */}
                {!moment(eventInfo.event.startStr).isBefore(moment.now()) ? <></> :
                    <Typography style={{ color: "white" }} variant="body2">
                        {translation.dictionary["performed"]}: {getCompletedEventsStr(moment(eventInfo.event.startStr).format("YYYY-MM-DD hh:mm:ss"), eventInfo.event.id)}
                    </Typography>
                }
            </Paper>
        )
    }

    return (
        <div>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color="action" />} 
                            checkedIcon={<CheckBoxIcon color="action" />}
                            checked={showUnitAssessmentEvents}
                            onChange={(event) => {
                                setShowUnitAssessmentEvents(!showUnitAssessmentEvents);
                            }}
                        />
                    }
                    label={translation.dictionary["unitAssessments"]}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color="action" />} 
                            checkedIcon={<CheckBoxIcon color="action" />}
                            checked={showUserAssessmentEvents}
                            onChange={(event) => {
                                setShowUserAssessmentEvents(!showUserAssessmentEvents);
                            }}
                        />
                    }
                    label={translation.dictionary["userAssessments"]}
                />
            </FormGroup>

            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                dateClick={handleDateClick}
                selectable
                events={getEvents()}
                eventClick={handleEventClick}
                eventContent={customEventContent}
            />

            {!dateClicked ? <></> :
                <SchedulerForm
                    formType={FormTypes.DATE}
                    showForm={() => setDateClicked(false)}
                    startDate={startDate}
                    selectedEvent={selectedAssessmentEvent}
                />}

            {!eventClicked ? <></> :
                <SchedulerForm
                    formType={FormTypes.EVENT}
                    showForm={() => setEventClicked(false)}
                    startDate={""}
                    selectedEvent={selectedAssessmentEvent}
                />}
        </div>
    )
}