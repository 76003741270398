import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp, faExclamationCircle, faArrowAltCircleDown, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

interface PrognosisChartProps {
  values: number[];
}

const PrognosisChart: React.FC<PrognosisChartProps> = ({ values }) => {
  const arrowSize = "1x"; 
  const upArrowColor = "green";
  const downArrowColor = "red";
  const errorColor = "orange";

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
      {/* Up Arrow */}
      {values[0] > 0 && (
        <FontAwesomeIcon
          icon={faArrowAltCircleUp}
          size={arrowSize}
          color={upArrowColor}
        />
      )}
      {/* Equal */}
      {values[0] == 0 && (
        <FontAwesomeIcon
          icon={faArrowAltCircleRight}
          size={arrowSize}
          color={errorColor}
        />
      )}
      {/* Down Arrow */}
      {values[0] < 0 && (
        <FontAwesomeIcon
          icon={faArrowAltCircleDown}
          size={arrowSize}
          color={downArrowColor}
        />
      )}
    {/* Up Arrow */}
    {values[1] > 0 && (
      <FontAwesomeIcon
        icon={faArrowAltCircleUp}
        size={arrowSize}
        color={upArrowColor}
      />
    )}
    {/* Equal */}
    {values[1] == 0 && (
      <FontAwesomeIcon
        icon={faArrowAltCircleRight}
        size={arrowSize}
        color={errorColor}
      />
    )}
    {/* Down Arrow */}
    {values[1] < 0 && (
      <FontAwesomeIcon
        icon={faArrowAltCircleDown}
        size={arrowSize}
        color={downArrowColor}
      />
    )}
    </div>
);
};

export default PrognosisChart;