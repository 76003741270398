import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import moment from "moment";
import { Bar, BarChart, LabelList, LabelListProps, ResponsiveContainer, Tooltip, TooltipProps as RechartsTooltipProps, XAxis, YAxis } from "recharts";
import Unit from "../../interfaces/entities/Unit";
import { UnitAssessmentEvent } from "../../interfaces/entities/UnitAssessmentEvent";
import { UserAssessmentEvent } from "../../interfaces/entities/UserAssessmentEvent";
import { ChartProps } from "../../interfaces/ui/Interfaces";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { store } from "../../redux/storeStates/store";
import { useSelector } from "react-redux";
import { Store } from "../../redux/storeStates/StoreStates";
import PrognosisChart from "./prognosis/PrognosisChart";

const colors = ["#FF3333", "#FFA500", "#33CC33"];

const getColor = (current: number, max: number, limit: number[]) => {
    let index = 0;
    if ((100 * current) / max >= limit[0]) {
        index++;
    }
    if ((100 * current) / max >= limit[1]) {
        index++;
    }
    return colors[index];
};

export function Statusbar(props: ChartProps) {

    const translation = useSelector((state: Store) => state.translation);
    const customStyle = {
        textAlign: "initial",
    };

    function getPrognosisVisuals(){
        if(props.includeUnits){
            let dates = [...props.units.flatMap(u=>u.unitAssessmentEvents.filter(ue=>ue.status==1).map(ue=>ue.plannedDate)).sort()];
            let lastDate = dates[dates.length-1];
            let prognosisValues = [0,0,0,0,0,0,0,0];
            let prognosis = [...props.units.flatMap(u=>u.unitAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.unitAssessment.prognosisLongterm + 2] += 1)];
            let max = Math.max(...prognosisValues);
            let longtermPrognosis = prognosisValues.indexOf(max);
            prognosisValues = [0,0,0,0,0,0,0,0];
            prognosis = [...props.units.flatMap(u=>u.unitAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.unitAssessment.prognosisShortterm + 2] += 1)];
            max = Math.max(...prognosisValues);
            let shorttermPrognosis = prognosisValues.indexOf(max);
            if(shorttermPrognosis)
            return <>{shorttermPrognosis > 2 ? <ArrowUpwardIcon /> : shorttermPrognosis < 2 ? <ArrowDownwardIcon /> : <ArrowForwardIcon/>}{longtermPrognosis > 2 ? <ArrowUpwardIcon /> : longtermPrognosis < 2 ? <ArrowDownwardIcon /> : <ArrowForwardIcon/>}</>;   
        } else if(props.includeUsers) {
            let dates = [...props.units.flatMap(u=>u.users).flatMap(us=>us.userAssessmentEvents).filter(ue=>ue.status==1).map(ue=>ue.plannedDate).sort()];
            let lastDate = dates[dates.length-1];
            let prognosisValues = [0,0,0,0,0,0,0,0];
            let prognosis = [...props.units.flatMap(u=>u.users).flatMap(us=>us.userAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.userAssessment.prognosisLongterm + 2] += 1)];
            let max = Math.max(...prognosisValues);
            let longtermPrognosis = prognosisValues.indexOf(max);
            prognosisValues = [0,0,0,0,0,0,0,0];
            prognosis = [...props.units.flatMap(u=>u.users).flatMap(us=>us.userAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.userAssessment.prognosisShortterm + 2] += 1)];
            max = Math.max(...prognosisValues);
            let shorttermPrognosis = prognosisValues.indexOf(max);
            if(shorttermPrognosis)
            return <>{shorttermPrognosis > 2 ? <ArrowUpwardIcon /> : shorttermPrognosis < 2 ? <ArrowDownwardIcon /> : <ArrowForwardIcon/>}{longtermPrognosis > 2 ? <ArrowUpwardIcon /> : longtermPrognosis < 2 ? <ArrowDownwardIcon /> : <ArrowForwardIcon/>}</>;   
        } else {
            return <></>
        }
    }
    function getPrognosis(){
        if(props.includeUnits){
            let dates = [...props.units.flatMap(u=>u.unitAssessmentEvents.filter(ue=>ue.status==1).map(ue=>ue.plannedDate)).sort()];
            let lastDate = dates[dates.length-1];
            let prognosisValues = [0,0,0,0,0,0,0,0];
            let prognosis = [...props.units.flatMap(u=>u.unitAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.unitAssessment.prognosisLongterm + 2] += 1)];
            let max = Math.max(...prognosisValues);
            let longtermPrognosis = prognosisValues.indexOf(max);
            prognosisValues = [0,0,0,0,0,0,0,0];
            prognosis = [...props.units.flatMap(u=>u.unitAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.unitAssessment.prognosisShortterm + 2] += 1)];
            max = Math.max(...prognosisValues);
            let shorttermPrognosis = prognosisValues.indexOf(max);
            return [shorttermPrognosis, longtermPrognosis];
        } else if(props.includeUsers) {
            let dates = [...props.units.flatMap(u=>u.users).flatMap(us=>us.userAssessmentEvents).filter(ue=>ue.status==1).map(ue=>ue.plannedDate).sort()];
            let lastDate = dates[dates.length-1];
            let prognosisValues = [0,0,0,0,0,0,0,0];
            let prognosis = [...props.units.flatMap(u=>u.users).flatMap(us=>us.userAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.userAssessment.prognosisLongterm + 2] += 1)];
            let max = Math.max(...prognosisValues);
            let longtermPrognosis = prognosisValues.indexOf(max) - 2;
            prognosisValues = [0,0,0,0,0,0,0,0];
            prognosis = [...props.units.flatMap(u=>u.users).flatMap(us=>us.userAssessmentEvents).filter(ue=>ue.status==1 && ue.plannedDate==lastDate).map(ue=> prognosisValues[ue.userAssessment.prognosisShortterm + 2] += 1)];
            max = Math.max(...prognosisValues);
            let shorttermPrognosis = prognosisValues.indexOf(max) - 2;
            return [shorttermPrognosis, longtermPrognosis];
        } else {
            return [0, 0]
        }
    }

    function getStatus(units: Unit[], from: string, to: string, includeUnits: boolean, includeUsers: boolean) {
        if (!(units.length > 0))
            return 0;
    
        to = moment(to).add(1, "day").format("YYYY-MM-DD");
        let mainValues: number[] = [];
        let avgSum: number = 0;
        let avgMain: number = 0;
            
        if (includeUnits) {
            let dates = [...props.units.flatMap(u=>u.unitAssessmentEvents.filter(ue=>ue.status==1).map(ue=>ue.plannedDate)).sort()];
            let lastDate = dates[dates.length-1];
            
            let events: UnitAssessmentEvent[] = [];
            units.forEach(unit => {
                let eventsWithinTimeSpan = unit.unitAssessmentEvents.filter(ev => moment(ev.plannedDate).isBetween(moment(from), moment(to)));
                events = events.concat(eventsWithinTimeSpan);
            })
    
            let performedAssessments = events.filter(function (e) {
                return e.emailStatus === 1 && e.status === 1 && e.unitAssessment && e.plannedDate == lastDate;
            });
    
            if (performedAssessments.length === 0)
                return 0;
    
            mainValues = performedAssessments.map(pa => pa.unitAssessment.assessmentMain);
            avgSum = mainValues.reduce((a, b) => a + b, 0);
            avgMain = avgSum / performedAssessments.length;
    
        } else if (includeUsers) {
            let dates = [...props.units.flatMap(u=>u.users).flatMap(u=>u.userAssessmentEvents.filter(ue=>ue.status==1).map(ue=>ue.plannedDate)).sort()];
            let lastDate = dates[dates.length-1];

            let events: UserAssessmentEvent[] = [];
            units.forEach(unit => {
                if (unit.users.length > 0) {
                    unit.users.forEach(user => {
                        let eventsWithinTimeSpan = user.userAssessmentEvents.filter(ev => moment(ev.plannedDate).isBetween(moment(from), moment(to)));
                        events = events.concat(eventsWithinTimeSpan);
                    })
                }
            })
            let performedAssessments = events.filter(function (e) {
                return e.emailStatus === 1 && e.status === 1 && e.userAssessment && e.plannedDate == lastDate;
            });
            if (performedAssessments.length === 0)
                return 0;
    
            mainValues = performedAssessments.map(pa => pa.userAssessment.assessmentMain);
            avgSum = mainValues.reduce((a, b) => a + b, 0);
            avgMain = avgSum / performedAssessments.length;
        }
    
        return parseFloat((10 * avgMain).toFixed(2));
    }

    function getCompletedEventsStr(units: Unit[], includeUnits: boolean, includeUsers: boolean) {
        if (!(units.length > 0))
            return;

        let totEvents = 0;
        let completetEvents = 0;

        if (includeUnits) {
            let dates = [...props.units.flatMap(u=>u.unitAssessmentEvents.filter(ue=>ue.status==1).map(ue=>ue.plannedDate)).sort()];
            let lastDate = dates[dates.length-1];

            units.forEach((unit) => {
                let ev = unit.unitAssessmentEvents.filter(ev => ev.plannedDate === lastDate);
                if (ev.length) {
                    if (ev[0].status === 1 && ev[0].emailStatus === 1) {
                        totEvents = totEvents + 1;
                        completetEvents = completetEvents + 1;
                    } else
                        totEvents = totEvents + 1;
                }
            })
            return completetEvents + "/" + totEvents + " " + translation.dictionary["completed_events"];
        } else if(includeUsers) {
            let dates = [...props.units.flatMap(u=>u.users).flatMap(u=>u.userAssessmentEvents.filter(ue=>ue.status==1).map(ue=>ue.plannedDate)).sort()];
            let lastDate = dates[dates.length-1];

            units.forEach(unit => {
                unit.users.forEach(user => {
                    let ev = user.userAssessmentEvents.filter(ev => ev.plannedDate === lastDate);
                    if (ev.length) {
                        if (ev[0].status === 1 && ev[0].emailStatus === 1) {
                            totEvents = totEvents + 1;
                            completetEvents = completetEvents + 1;
                        } else
                            totEvents = totEvents + 1;
                    }
                });
            })
            return completetEvents + "/" + totEvents + " " + translation.dictionary["completed_events"];
        } else {
            return "";
        }
    }

    const CustomTooltip: React.FC<RechartsTooltipProps<string,string>> = ({
        active,
        payload,
      }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p>{getCompletedEventsStr(props.units, props.includeUnits, props.includeUsers)}</p>
              {/* <p>{`X: ${payload[0].payload.x}`}</p> */}
              {/* <p>{`Y: ${payload[0].payload.y}`}</p> */}
            </div>
          );
        }
      
        return null;
      };      
      
    return (
        // <Paper style={{ background: "linear-gradient(90deg, rgba(195,2,2,1) 25%, rgba(240,227,5,1) 65%, rgba(5,176,0,1) 100%)" }}>
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                // '& > :not(style)': {
                // m: 1,
                // width: 500,
                // height: 300,
                // },
            }}
            >
        {/* <Paper > */}
        <Paper style={{ background: "white", width: "80%" }}>
        <ResponsiveContainer width="100%" maxHeight={30} minHeight={10} >
            <BarChart margin={{ right: 30 }} maxBarSize={100} layout="vertical" data={[{ name: "Status", current: getStatus(props.units, props.from, props.to, props.includeUnits, props.includeUsers), max: 100, label: getStatus(props.units, props.from, props.to, props.includeUnits, props.includeUsers) > 25 ? getCompletedEventsStr(props.units, props.includeUnits, props.includeUsers) : "" }]}>
                <XAxis hide={true} axisLine={false} type="number" domain={[0, 100]} />
                <YAxis type="category" dataKey="name" axisLine={false} tickLine={true} />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                    dataKey="current"
                    minPointSize={5}
                    fill={getColor(getStatus(props.units, props.from, props.to, props.includeUnits, props.includeUsers), 100, [40, 60])}
                    // fill={"rgba(0,0,0,0)"} //Transparent bar
                    background={{ fill: "#CCC" }}
                // barSize={0}
                >
                    <LabelList dataKey="label" />
                    {/* <Tooltip content={<p>getCompletedEventsStr(props.units, props.includeUnits, props.includeUsers)</p>} /> */}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
        </Paper>
        {/* <Paper> */}
        <Paper style={{ background: "white", width: "20%", float: "right" }}>
            <div style={{float: "right", fontSize:"xx-large", width: "100%", textAlign: "initial"}}>
                <Typography>{translation.dictionary["prognosis"]} ({translation.dictionary["short"]}, {translation.dictionary["long"]})</Typography>
                <PrognosisChart values={getPrognosis()} />
                {/* {getPrognosisVisuals()} */}
            </div>
        </Paper>
        </Box>
        )
}