import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import Unit from '../../interfaces/entities/Unit';
import User from '../../interfaces/entities/User';
import { store } from '../../redux/storeStates/store';
import { ActionTypes } from '../../redux/actionTypes';
import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { Store } from '../../redux/storeStates/StoreStates';
import { useSelector } from 'react-redux';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckboxWrapper from './CustomCheckbox';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';

interface Props {
  columns: GridColDef[];
  rows: any[];
}

function isUnit(obj: any): obj is Unit {
  return obj === undefined ? false : obj.unitId !== undefined;
}

function isUser(obj: any): obj is User {
  return obj === undefined ? false : obj.userId !== undefined;
}

//Returns what should be used as ID based on type.
function customId(row: GridRowData, props: any) {
  if (isUnit(props.rows[0]))
    return row.unitId
  if (isUser(props.rows[0]))
    return row.userId
}

export default function DataTable(props: Props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState(props.columns);
  const translation = useSelector((state: Store) => state.translation);

  useEffect(() => {
    setColumns(props.columns.map((column) => { column.headerName = column.field ? translation.dictionary[column.field] : ""; return column; }, []));
  }, [translation])

  return (
    <Paper style={{ minHeight: 500, width: "100%" }} elevation={3}>
      <DataGrid
        components={{
          Checkbox: CheckboxWrapper,
        }}        
        rows={props.rows.filter(row => row.active === 1)}
        columns={columns} 
        pageSize={rowsPerPage}
        onPageSizeChange={(value) => setRowsPerPage(value)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        checkboxSelection
        disableColumnFilter
        style={{ border: "0px" }}

        //Dispatches an array of selected objects to Store based on object-type
        onSelectionModelChange={(selectedRows) => {

          if (isUnit(props.rows[0])) {
            let selectedObjects: Unit[] = [];
            selectedRows.forEach(ID => {
              selectedObjects.push(props.rows.find(row => row.unitId === ID))
            });
            store.dispatch({
              type: ActionTypes.SET_SELECTED_UNITS, payload: selectedObjects
            });
          }

          if (isUser(props.rows[0])) {
            let selectedObjects: User[] = [];
            selectedRows.forEach(ID => {
              selectedObjects.push(props.rows.find(row => row.userId === ID))
            });
            store.dispatch({
              type: ActionTypes.SET_SELECTED_USERS, payload: selectedObjects
            });
          }
        }}

        //Sets id to be equal to objects id-field
        getRowId={(row) => customId(row, props)}
      />
    </Paper>
  );
}