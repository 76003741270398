import React, { MouseEventHandler, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, useTreeItem, TreeItemContentProps, } from '@mui/lab/TreeItem';
import { Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Unit from '../../interfaces/entities/Unit';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/storeStates/StoreStates';
import { theme } from './Theme';
import { Checkbox } from '@material-ui/core';
import { preventDefault } from '@fullcalendar/react';

interface Props {
    feedback: Function;
    selected: string[];
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    selected: boolean;
    _onChange: ((selected: boolean) => void);
};

declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.primary})`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: theme.palette.text.secondary,
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            marginLeft: 0,
            '& $content': {
                paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        disabled: {
            backgroundColor: theme.palette.action.hover,
        },
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
        },
    }),
);

function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles(theme);
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, selected, _onChange, ...other } = props;

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        props._onChange(event.target.checked);
    }

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon className={classes.labelIcon} />
                    <Typography component={'span'} variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography component={'span'} variant="caption" color="secondary">
                        {labelInfo}
                    </Typography>
                    <Checkbox
                        checked={props.selected}
                        icon={<CheckBoxOutlineBlankIcon color="action" />}
                        checkedIcon={<CheckBoxIcon color="action" />}
                        onChange={handleCheck}
                    />
                </div>
            }
            {...other}

        />
    );
}

const useStyles = makeStyles(
    createStyles({
        root: {
            height: "auto",
            flexGrow: 1,
            maxWidth: 450,
            alignContent: "left",
            textAlign: "left",
        },
    }),
);

export default function OrganizationTreeView(props: Props) {
    const classes = useStyles();
    const organization = useSelector((state: Store) => state.organization);
    const units = useSelector((state: Store) => state.units);

    const [expanded, setExpanded] = useState<string[]>(["1"]);
    const [selectedIds, setSelectedIds] = useState<string[]>(props.selected);
    const [activeUnits, setActiveUnits] = useState<Unit[]>([]);

    const [disabledNode, setDisabledNode] = useState({} as Unit);    

    const [checked, setChecked] = useState(false);

    //TODO - updates efter 2 clicks (With the commented code below)
    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        if (!(event.target instanceof HTMLInputElement)) {
            setExpanded(nodeIds);
        }
    };

    useEffect(() => {
        props.feedback(selectedIds, false);
    }, [selectedIds])

    useEffect(() => {
        // Connect orphens
        let organizationId = organization.organizationId;
        // find orhpen
        let orphans = [] as Unit[];
        let unitsAllNonTop = [] as Unit[];
        // Add orphen
        units.forEach(u=>{
            // Check units that are subunits to ensure they are not orphans
            if(!(!u.parentUnit.id || u.parentUnit.id === "0" || u.parentUnit.id==organizationId)) {
                orphans.push(u);
            } 
            // Add all sub units
            u.units.forEach(item => unitsAllNonTop.push(item))
        })
        setActiveUnits([...units].filter(unit => unit.active === 1));
        let orphansDiff = orphans.filter((o)=>!unitsAllNonTop.includes(o));
        let activeUnitsTemp = [] as Unit[];
        if(orphansDiff && orphansDiff.length==1) {
            let oUnit = orphansDiff[0];
            units.forEach(u=>{
                let unit = u;
                if((u.unitId==orphansDiff[0].parentUnit.id)) {
                    unit.units.push(oUnit);
                } 
                activeUnitsTemp.push(unit);
            })    
        }
    }, [units])

    function handleSelection(type: string, nodeId: string, selected: boolean) {
        let toSelect: string[] = [...selectedIds];
        switch (type) {
            case "ORGANIZATION":
                if (selected) {
                    toSelect = getAllIds(false);
                } else {
                    toSelect = [];
                }
                break;

            case "UNIT":
                let selectedUnit: Unit = {} as Unit;
                activeUnits.forEach(unit => {
                    if (unit.unitId === nodeId)
                        selectedUnit = unit;
                });

                if (selected) {
                    toSelect.push(selectedUnit.unitId);
                    UnitSelect(selectedUnit, toSelect);
                } else {
                    let toUnselect: string[] = [selectedUnit.unitId];
                    UnitSelect(selectedUnit, toUnselect);
                    toSelect = toSelect.filter((id) => (!toUnselect.includes(id) && id !== "1"));
                }
                break;

            default:
                break;
        }
        setSelectedIds(toSelect);
        setChecked(true);
    }

    function getAllIds(setState: boolean) {
        let toSelect: string[] = ["1"];

        if (activeUnits.length < 1)
            return [];

        activeUnits.forEach(unit => {
            toSelect.push(unit.unitId);
        });

        if (setState)
            setSelectedIds(toSelect);

        return toSelect
    }

    const UnitSelect = (unit: Unit, childrenList: string[]) => {
        const isChildren = unit.units && unit.units.length;
        if (isChildren && unit.active === 1) {
            unit.units.forEach((unit) => {
                childrenList.push(unit.unitId);
                UnitSelect(unit, childrenList);
            })
        }
    }

    const TreeRender = (unit: Unit) => {
        const isChildren = unit.units && unit.units.length;
        if (unit.active !== 1)
            return;

        if (isChildren) {
            return (
                <StyledTreeItem color='secondary' key={unit.unitId} nodeId={unit.unitId} labelText={unit.name} labelIcon={GroupsIcon} disabled={false} selected={selectedIds.includes(unit.unitId)} _onChange={(selected) => handleSelection("UNIT", unit.unitId, selected)}>
                    {unit.units.sort((u1, u2)=> (u1.name > u2.name) ? 1 : (u2.name > u1.name) ? -1 : 0).map((unit) => TreeRender(unit))}
                </StyledTreeItem>
            );
        }
        return <StyledTreeItem color='secondary' key={unit.unitId} nodeId={unit.unitId} labelText={unit.name} labelIcon={GroupsIcon} disabled={false} selected={selectedIds.includes(unit.unitId)} _onChange={(selected) => handleSelection("UNIT", unit.unitId, selected)} />;
    };

    const getRootElement = () => {
        return <BusinessIcon color='action' />;
    }

    return (
        <TreeView
            className={classes.root}
            expanded={expanded}
            selected={selectedIds[0] === "1" && selectedIds.length === 1 ? getAllIds(true) : selectedIds} // If only id "1" is selected => select all
            disableSelection
            onNodeToggle={handleToggle}
            defaultExpanded={['1']}
            defaultCollapseIcon={<KeyboardArrowDownIcon color="action" />}
            defaultExpandIcon={<KeyboardArrowRightIcon color="action" />}
        >
            <StyledTreeItem
                key={"1"}
                nodeId={"1"}
                labelText={organization.name}
                labelIcon={getRootElement}
                selected={selectedIds.includes("1")}
                _onChange={(selected) => handleSelection("ORGANIZATION", "1", selected)}
            >
                {activeUnits.filter((u) => !u.parentUnit.id || u.parentUnit.id === "0" || u.parentUnit.id === organization.organizationId).sort((u1, u2)=> (u1.name > u2.name) ? 1 : (u2.name > u1.name) ? -1 : 0).map((unit) => { return TreeRender(unit) })}
                {/*  */}
                {/* {organization.units.map((unit) => { return TreeRender(unit) })} */}
            </StyledTreeItem>
        </TreeView>
    )
}