import { Modal } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import SimpleDialogDemo from './components/common/SimpleDialog';
import SimpleDialog from './components/common/SimpleDialog';
import Paperbase from './components/Paperbase';
import DataInit from "./data/DataInit";
import { store } from './redux/storeStates/store';
import { Store } from './redux/storeStates/StoreStates';
import Keycloak from 'keycloak-js';
import { ActionTypes } from './redux/actionTypes';
import PreventBackButtonLeaving from './components/PreventBackButtonLeaving';

interface IProp {
  keycloak?: any;
}

function App(props: IProp) {

  const dataLoaded = useSelector((state: Store) => state.dataLoaded);
  const availableOrganizations = useSelector((state: Store) => state.availableOrganizations);
  const selectedOrganizationId = useSelector((state: Store) => state.selectedOrganizationId);
  const keycloak = useSelector((state: Store) => state.keycloak);

  /**
   * Dev
   */
  // DataInit.DummyData();

  /**
   * Prod
   */
  useEffect(() => {
    if(!dataLoaded) {
      if(keycloak == null) {
        store.dispatch({ type: ActionTypes.SET_KEYCLOAK, payload: props.keycloak });
      }else if(availableOrganizations.length == 0) {
        DataInit.Login();  
      } else if(availableOrganizations.length == 1) {
        DataInit.Data(availableOrganizations[0].organizationId);  
      } else if(selectedOrganizationId != "") {
        DataInit.Data(selectedOrganizationId);  
      }
    }else{
      // console.log("LOADED AND IN APP");
    }
  }, [keycloak, availableOrganizations, selectedOrganizationId]) //this useEffect will only call on its first render

  return (
    <div>
    {store.getState().dataLoaded  && <div className="App">
      <Paperbase />
    </div> }
    {!store.getState().dataLoaded  && availableOrganizations.length > 1 && 
    <div>  
      <SimpleDialogDemo />
    </div> }
    <PreventBackButtonLeaving />
    </div>
  );
}

export default App;
