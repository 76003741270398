import { AppBar, ButtonGroup, Divider, IconButton, Paper, Stack, Toolbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { unitColumns } from "../interfaces/ui/Columns";
import { Store } from "../redux/storeStates/StoreStates";
import BasicTable from "./common/BasicTable";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import ConfirmationDialog from "./common/ConfirmationDialog";
import { store } from "../redux/storeStates/store";
import { ActionTypes } from "../redux/actionTypes";
import { FormTypes } from "../interfaces/ui/Enums";
import OrganizationTreeView from "./common/TreeView";
import Unit from "../interfaces/entities/Unit";
import Routes from "../data/Routes";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      height: "100%",
    },
  });

export default function Units() {
    const classes = useStyles();
    const units = useSelector((state: Store) => state.units);
    const selectedUnits = useSelector((state: Store) => state.selectedUnits);
    const translation = useSelector((state: Store) => state.translation);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>(["1"]);
    const [unitsToShow, setUnitsToShow] = useState<Unit[]>([]);

    useEffect(() => {
        if(units && units.length !=0 && selectedIds.includes(units[units.length-1].unitId)) {
            let tempIds = selectedIds;
            tempIds.push(units[units.length-1].unitId);
            setSelectedIds(tempIds);
        }
        setUnitsToShow([...units].filter(unit => unit.active === 1));
    }, [units])

    return (
        <Stack direction="row" spacing={2} className={classes.root}>
            <Paper elevation={3}>
                <Stack alignItems="center" marginBottom={2}>
                    <Toolbar>
                        <ButtonGroup>
                            <Tooltip title={translation.dictionary["new"]}>
                                <div>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            store.dispatch({
                                                type: ActionTypes.SET_SHOW_FORM, payload: { show: true, type: FormTypes.NEW }
                                            });
                                        }}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title={translation.dictionary["edit"]}>
                                <div>
                                    <IconButton
                                        disabled={!(selectedUnits.length === 1)}
                                        color="primary"
                                        onClick={() => {
                                            store.dispatch({
                                                type: ActionTypes.SET_SHOW_FORM, payload: { show: true, type: FormTypes.EDIT }
                                            });
                                        }}>
                                        <Edit />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title={translation.dictionary["remove"]}>
                                <div>
                                    <IconButton
                                        disabled={!(selectedUnits.length > 0)}
                                        color="primary"
                                        onClick={() => {
                                            setShowConfirmationDialog(true);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </ButtonGroup>
                    </Toolbar>

                    <OrganizationTreeView
                        selected={selectedIds}
                        feedback={(selectedIds: string[]) => {
                            let selected: Unit[] = units.filter(unit => selectedIds.includes(unit.unitId));
                            setSelectedIds(selectedIds);
                            setUnitsToShow(selected);
                        }} />
                </Stack>
            </Paper>

            <BasicTable columns={unitColumns} rows={unitsToShow} />

            <ConfirmationDialog
                show={showConfirmationDialog}
                label={translation.dictionary["remove"]}
                text={translation.dictionary["confirm"]}
                yesButtonLabel={translation.dictionary["yes"]}
                noButtonLabel={translation.dictionary["no"]}
                onChoice={(choice) => {
                    if (choice) {
                        Routes.Unit.delete(selectedUnits);
                    }
                    setShowConfirmationDialog(false);
                }} />
        </Stack>
    )
}