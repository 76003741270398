import moment from "moment"
import Organization from "../interfaces/entities/Organization"
import Unit from "../interfaces/entities/Unit"
import UnitAssessment from "../interfaces/entities/UnitAssessment"
import { v4 as RandomUUID } from "uuid";
import { UnitAssessmentEvent } from "../interfaces/entities/UnitAssessmentEvent";
import Configuration from "../interfaces/entities/Configuration";
import ImpactFactorConfiguration from "../interfaces/entities/ImpactFactorConfiguration";
import ImpactFactor from "../interfaces/entities/ImpactFactor";
import defaultEntities from "./EntityUtils";
import ImpactAssessmentValue from "../interfaces/entities/ImpactAssessmentValue";
import UserAssessment from "../interfaces/entities/UserAssessment";
import { UserAssessmentEvent } from "../interfaces/entities/UserAssessmentEvent";
import User from "../interfaces/entities/User";

function getWeeksFromNow(weeks: number) {
    let today = moment(new Date());
    today.add(weeks, "weeks");
    return moment(today).format("YYYY-MM-DD hh:mm:ss");
}

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const DummyImpactFactors: ImpactFactor[] = [
    {
        impactFactorId: "f1f1f3c7-1a4f-4ecc-865f-0efb62b4f5c8",
        name: "WorkLoad",
        description: "Description",
        positive: 0,
    },
    {
        impactFactorId: "f2f1f3c7-1a4f-4ecc-865f-0efb62b4f5c8",
        name: "Anxiety",
        description: "Description",
        positive: 0,
    },
    {
        impactFactorId: "f3f1f3c7-1a4f-4ecc-865f-0efb62b4f5c8",
        name: "Conceptual",
        description: "Description",
        positive: 0,
    },
    {
        impactFactorId: "f4f1f3c7-1a4f-4ecc-865f-0efb62b4f5c8",
        name: "Moral",
        description: "Description",
        positive: 0,
    },
    {
        impactFactorId: "f5f1f3c7-1a4f-4ecc-865f-0efb62b4f5c8",
        name: "Psychosocial",
        description: "Description",
        positive: 0,
    },
    {
        impactFactorId: "f6f1f3c7-1a4f-4ecc-865f-0efb62b4f5c8",
        name: "Other",
        description: "Description",
        positive: 0,
    },
]

function randomImpactFactorAssessment(index?: number) {
    return {
        comment: "Det här är en kommentar..",
        value: randomIntFromInterval(0, 2),
        impactFactor: DummyImpactFactors[index !== undefined ? index : randomIntFromInterval(0, 5)],
    }
}

function randomUnitAssessment() {
    let min = randomIntFromInterval(1, 5);
    let max = randomIntFromInterval(5, 10);
    let main = (min + max) / 2;

    let assessment: UnitAssessment = {
        assessmentId: RandomUUID(),
        assessmentMin: min,
        assessmentMax: max,
        assessmentMain: main,
        prognosisLongterm: randomIntFromInterval(1, 5),
        prognosisShortterm: randomIntFromInterval(1, 5),
        prognosisLongtermComment: "This is a longterm prognosis",
        prognosisShorttermComment: "This is a shortterm prognosis",
        actionsLongterm: "This is a longterm action",
        actionsShortterm: "This is a shortterm action",
        submissionDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        unitImpactAssessmentValues: [randomImpactFactorAssessment(0), randomImpactFactorAssessment(1), randomImpactFactorAssessment(3)]
    };

    return assessment;
}

function randomUserAssessment() {
    let assessment: UserAssessment = {
        assessmentId: RandomUUID(),
        assessmentMain: randomIntFromInterval(1, 10),
        prognosisLongterm: randomIntFromInterval(1, 5),
        prognosisShortterm: randomIntFromInterval(1, 5),
        prognosisLongtermComment: "This is a longterm prognosis",
        prognosisShorttermComment: "This is a shortterm prognosis",
        submissionDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        userImpactAssessmentValues: [randomImpactFactorAssessment(0), randomImpactFactorAssessment(1), randomImpactFactorAssessment(3)],
    };

    return assessment;
}

function DummyUnitAssessmentEvents() {

    let assessmentEvents: UnitAssessmentEvent[] = [
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(-3),
            status: randomIntFromInterval(0, 1),
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            unit: {} as Unit,
            unitAssessment: randomUnitAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(-2),
            status: 1,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            unit: {} as Unit,
            unitAssessment: randomUnitAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(-1),
            status: 1,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            unit: {} as Unit,
            unitAssessment: randomUnitAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(0),
            status: 1,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            unit: {} as Unit,
            unitAssessment: randomUnitAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 0,
            plannedDate: getWeeksFromNow(1),
            status: 0,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            unit: {} as Unit,
            unitAssessment: randomUnitAssessment(),
        }
    ]

    return assessmentEvents;
}

function DummyUserAssessmentEvents() {

    let assessmentEvents: UserAssessmentEvent[] = [
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(-3),
            status: randomIntFromInterval(0, 1),
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            user: {} as User,
            userAssessment: randomUserAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(-2),
            status: 1,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            user: {} as User,
            userAssessment: randomUserAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(-1),
            status: 1,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            user: {} as User,
            userAssessment: randomUserAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 1,
            plannedDate: getWeeksFromNow(0),
            status: 1,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            user: {} as User,
            userAssessment: randomUserAssessment(),
        },
        {
            assessmentEventId: RandomUUID(),
            emailStatus: 0,
            plannedDate: getWeeksFromNow(1),
            status: 0,
            submittedTimeStamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            user: {} as User,
            userAssessment: randomUserAssessment(),
        }
    ]

    return assessmentEvents;
}

export const DummyImpactFactorConfiguration1: ImpactFactorConfiguration[] = [
    {
        impactFactorConfigurationId: RandomUUID(),
        impactFactor: DummyImpactFactors[0],
        displayName: "Arbetsbelastning",
        commentVisible: 1,
    },
    {
        impactFactorConfigurationId: RandomUUID(),
        impactFactor: DummyImpactFactors[1],
        displayName: "Ångest",
        commentVisible: 1,
    },
    {
        impactFactorConfigurationId: RandomUUID(),
        impactFactor: DummyImpactFactors[3],
        displayName: "Moral",
        commentVisible: 1,
    }
]

export const DummyImpactFactorConfiguration2: ImpactFactorConfiguration[] = [
    {
        impactFactorConfigurationId: RandomUUID(),
        impactFactor: DummyImpactFactors[0],
        displayName: "Arbetsbelastning",
        commentVisible: 1,
    },
    {
        impactFactorConfigurationId: RandomUUID(),
        impactFactor: DummyImpactFactors[3],
        displayName: "Moral",
        commentVisible: 1,
    }
]

export const DummyConfigurations: Configuration[] = [
    {
        configurationId: RandomUUID(),
        name: "Config 1",
        type: 1,
        active: 1,
        impactFactorConfigurations: DummyImpactFactorConfiguration1,
    },
    {
        configurationId: RandomUUID(),
        name: "Config 2",
        type: 1,
        active: 0,
        impactFactorConfigurations: DummyImpactFactorConfiguration2,
    }
]

export const DummyUnits: Unit[] = [
    {
        unitId: RandomUUID(),
        name: "DummyUnit",
        description: "Im a dummy unit!",
        size: 55,
        active: 1,
        unitAssessmentEvents: DummyUnitAssessmentEvents(),
        users: [{
            userId: RandomUUID(),
            email: "dummy.user@DU.se",
            firstName: "Abraham",
            lastName: "Lincoln",
            workTitle: "Boss",
            unitSensor: 1,
            active: 1,
            userAssessmentEvents: DummyUserAssessmentEvents(),
            unit: { id: "", name: "" },
        },
        {
            userId: RandomUUID(),
            email: "dummy.user2@DU.se",
            firstName: "Albert",
            lastName: "Einstein",
            workTitle: "CEO",
            unitSensor: 0,
            active: 0,
            userAssessmentEvents: DummyUserAssessmentEvents(),
            unit: { id: "", name: "" },
        }],
        units: [
            {
                unitId: RandomUUID(),
                name: "Healthcare",
                description: "Im a dummy unit!",
                size: 55,
                active: 1,
                unitAssessmentEvents: DummyUnitAssessmentEvents(),
                users: [{
                    userId: RandomUUID(),
                    email: "dummy.user3@DU.se",
                    firstName: "Elvis",
                    lastName: "Presley",
                    workTitle: "Boss",
                    unitSensor: 1,
                    active: 1,
                    userAssessmentEvents: DummyUserAssessmentEvents(),
                    unit: { id: "", name: "" },
                },
                {
                    userId: RandomUUID(),
                    email: "dummy.user4@DU.se",
                    firstName: "Kurt",
                    lastName: "Cobain",
                    workTitle: "CEO",
                    unitSensor: 0,
                    active: 0,
                    userAssessmentEvents: DummyUserAssessmentEvents(),
                    unit: { id: "", name: "" },
                }],
                units: [{
                    unitId: RandomUUID(),
                    name: "Magagement",
                    description: "Im a dummy unit!",
                    size: 55,
                    active: 1,
                    unitAssessmentEvents: DummyUnitAssessmentEvents(),
                    users: [{
                        userId: RandomUUID(),
                        email: "dummy.user5@DU.se",
                        firstName: "John",
                        lastName: "Lennon",
                        workTitle: "Boss",
                        unitSensor: 1,
                        active: 1,
                        userAssessmentEvents: DummyUserAssessmentEvents(),
                        unit: { id: "", name: "" },
                    },
                    {
                        userId: RandomUUID(),
                        email: "dummy.user6@DU.se",
                        firstName: "Marilyn",
                        lastName: "Monroe",
                        workTitle: "CEO",
                        unitSensor: 0,
                        active: 0,
                        userAssessmentEvents: DummyUserAssessmentEvents(),
                        unit: { id: "", name: "" },
                    }],
                    units: [],
                    parentUnit: { id: "", name: "" },
                },
                {
                    unitId: RandomUUID(),
                    name: "Hr",
                    description: "Im a dummy unit!",
                    size: 55,
                    active: 1,
                    unitAssessmentEvents: DummyUnitAssessmentEvents(),
                    users: [{
                        userId: RandomUUID(),
                        email: "dummy.user7@DU.se",
                        firstName: "Stevie",
                        lastName: "Wonder",
                        workTitle: "Boss",
                        unitSensor: 1,
                        active: 1,
                        userAssessmentEvents: DummyUserAssessmentEvents(),
                        unit: { id: "", name: "" },
                    },
                    {
                        userId: RandomUUID(),
                        email: "dummy.user8@DU.se",
                        firstName: "Elton",
                        lastName: "John",
                        workTitle: "CEO",
                        unitSensor: 0,
                        active: 0,
                        userAssessmentEvents: DummyUserAssessmentEvents(),
                        unit: { id: "", name: "" },
                    }],
                    units: [],
                    parentUnit: { id: "", name: "" },
                }],
                parentUnit: { id: "", name: "" },
            }
        ],
        parentUnit: { id: "", name: "" },
    },
    {
        unitId: RandomUUID(),
        name: "DummyUnit2",
        description: "Im a dummy unit!",
        size: 1,
        active: 1,
        unitAssessmentEvents: DummyUnitAssessmentEvents(),
        users: [{
            userId: RandomUUID(),
            email: "dummy.user9@DU.se",
            firstName: "Whitney",
            lastName: "Houston",
            workTitle: "Boss",
            unitSensor: 1,
            active: 1,
            userAssessmentEvents: DummyUserAssessmentEvents(),
            unit: { id: "", name: "" },
        },
        {
            userId: RandomUUID(),
            email: "dummy.user10@DU.se",
            firstName: "Dolly",
            lastName: "Parton",
            workTitle: "CEO",
            unitSensor: 0,
            active: 0,
            userAssessmentEvents: DummyUserAssessmentEvents(),
            unit: { id: "", name: "" },
        }],
        units: [{
            unitId: RandomUUID(),
            name: "Automotives",
            description: "Im a dummy unit!",
            size: 1,
            active: 1,
            unitAssessmentEvents: DummyUnitAssessmentEvents(),
            users: [{
                userId: RandomUUID(),
                email: "dummy.user11@DU.se",
                firstName: "Aretha",
                lastName: "Franklin",
                workTitle: "Boss",
                unitSensor: 1,
                active: 1,
                userAssessmentEvents: DummyUserAssessmentEvents(),
                unit: { id: "", name: "" },
            },
            {
                userId: RandomUUID(),
                email: "dummy.user12@DU.se",
                firstName: "Ray",
                lastName: "Charles",
                workTitle: "CEO",
                unitSensor: 0,
                active: 0,
                userAssessmentEvents: DummyUserAssessmentEvents(),
                unit: { id: "", name: "" },
            }],
            units: [{
                unitId: RandomUUID(),
                name: "Sales",
                description: "Im a dummy unit!",
                size: 1,
                active: 1,
                unitAssessmentEvents: DummyUnitAssessmentEvents(),
                users: [{
                    userId: RandomUUID(),
                    email: "dummy.user13@DU.se",
                    firstName: "Sam",
                    lastName: "Cooke",
                    workTitle: "Boss",
                    unitSensor: 1,
                    active: 1,
                    userAssessmentEvents: DummyUserAssessmentEvents(),
                    unit: { id: "", name: "" },
                },
                {
                    userId: RandomUUID(),
                    email: "dummy.user14@DU.se",
                    firstName: "Dolly",
                    lastName: "Parton",
                    workTitle: "CEO",
                    unitSensor: 0,
                    active: 0,
                    userAssessmentEvents: DummyUserAssessmentEvents(),
                    unit: { id: "", name: "" },
                }],
                units: [],
                parentUnit: { id: "", name: "" },
            },
            {
                unitId: RandomUUID(),
                name: "Helpdesk",
                description: "Im a dummy unit!",
                size: 1,
                active: 1,
                unitAssessmentEvents: DummyUnitAssessmentEvents(),
                users: [{
                    userId: RandomUUID(),
                    email: "dummy.user15@DU.se",
                    firstName: "Bob",
                    lastName: "Dylan",
                    workTitle: "Boss",
                    unitSensor: 1,
                    active: 1,
                    userAssessmentEvents: DummyUserAssessmentEvents(),
                    unit: { id: "", name: "" },
                },
                {
                    userId: RandomUUID(),
                    email: "dummy.user16@DU.se",
                    firstName: "Tina",
                    lastName: "Turner",
                    workTitle: "CEO",
                    unitSensor: 0,
                    active: 0,
                    userAssessmentEvents: DummyUserAssessmentEvents(),
                    unit: { id: "", name: "" },
                }],
                units: [],
                parentUnit: { id: "", name: "" },
            }],
            parentUnit: { id: "", name: "" },
        }],
        parentUnit: { id: "", name: "" },
    },
]

export const DummyOrganization: Organization = {
    organizationId: RandomUUID(),
    name: "DummyOrganization",
    organizationNumber: "123456789",
    description: "Im a dummy organiation!",
    num_employees: 99,
    units: [],
    configurations: [],
}

//Exact id for org in the api´s h2-database
export const DummySelectedOrganizationId = "8dd40ca5-29a6-4c1a-95b0-da6c10b67cf1";