import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ActionList from "./ActionList";
import * as Category from "./Category";
import * as Timespan from "./Timespan";
import * as Priority from "./Priority";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { LocalizationProvider } from '@mui/lab';
import { Grid, Stack } from '@mui/material';
import moment from 'moment';
import * as CommentType from "./CommentType";
import { store } from '../../../redux/storeStates/store';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux/storeStates/StoreStates';
import { ActionTypes } from '../../../redux/actionTypes';
import OrganizationTreeView from '../../common/TreeView';
import { makeStyles } from '@material-ui/styles';
import Unit from '../../../interfaces/entities/Unit';

const useStyles = makeStyles({
    gridItem: {
      borderRight: '1px solid darkgray',
      paddingRight: '5px',
      height: '100%', 
    },
    headerSetting: {
        paddingRight: '5px',
        height: '100%', 
    }
  });

function ActionsView() {
    const classes = useStyles();

    const units = useSelector((state: Store) => state.units);
    const [selectedUnits, setSelectedUnits] = useState<Unit[]>([...units]);

    const [searchString, setSearchString] = useState("");
    const [categoryFilter, setCategoryFilter] = useState(-1);
    const [timespanFilter, setTimespanFilter] = useState(-1);
    const [commentTypeFilter, setCommentTypeFilter] = useState(-1);
    const [priorityFilter, setPriorityFilter] = useState(-1);
    const [sortBy, setSortBy] = useState("newest");
    const [selectedDates, setSelectedDates] = useState<{ from: string, to: string }>({
        from: moment(moment.now()).subtract(26, "weeks").format("YYYY-MM-DD"),
        to: moment(moment.now()).format("YYYY-MM-DD")
    });

    const checkboxFilter = useSelector((state: Store) => state.dashboardCheckboxFilter);

    const translation = useSelector((state: Store) => state.translation);

    function getInactiveUnits() {
        return units.filter(unit => unit.active === 0);
    }

    const handleSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length > 2)
            setSearchString(e.currentTarget.value.toLowerCase());
        else
            setSearchString("");
    }

    const handleCategoryChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setCategoryFilter(e.target.value as number);
    }

    const handleTimespanChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setTimespanFilter(e.target.value as number);
    }

    const handleCommentTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setCommentTypeFilter(e.target.value as number);
    }

    const handlePriorityChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setPriorityFilter(e.target.value as number);
    }

    const handleSortChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSortBy(e.target.value as string);
    }

    return (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
            {/* <Typography variant="h6">Textkommentarer</Typography> */}
            <div style={{ marginBottom: 16 }}>
                <TextField type="text" label={<Typography>{translation.dictionary["search"]}</Typography>} onChange={handleSearchChanged} style={{ marginRight: 16 }} />

                {/* <FormControl style={{ marginRight: 16 }}>
                    <InputLabel id="priority-form-label">Prioritet</InputLabel>
                    <Select
                        value={priorityFilter}
                        labelId="priority-form-label"
                        onChange={handlePriorityChange}
                    >
                        <MenuItem value={-1}>Alla</MenuItem>
                        {Priority.PRIORITY_STRINGS.map(
                            (priorityString, index) => {
                                return <MenuItem value={index} key={index}>{priorityString}</MenuItem>
                            }
                        )}
                    </Select>
                </FormControl> */}

                {/* <FormControl style={{ marginRight: 16 }}>
                    <InputLabel id="category-form-label">Kategori</InputLabel>
                    <Select
                        value={categoryFilter}
                        labelId="category-form-label"
                        onChange={handleCategoryChange}
                    >
                        <MenuItem value={-1}>Alla</MenuItem>
                        {Category.CATEGORY_STRINGS.map(
                            (categoryString, index) => {
                                return <MenuItem value={index} key={index}>{categoryString}</MenuItem>
                            }
                        )}
                        <MenuItem value={-2}>Osatt</MenuItem>
                    </Select>
                </FormControl> */}
                {/* <FormControl style={{ marginRight: 16 }}>
                    <InputLabel id="assessment-type-form-label">Skattningar</InputLabel>
                    <Select
                        value={timespanFilter}
                        labelId="assessment-type-form-label"
                        onChange={handleTimespanChange}
                    >
                        <MenuItem value={-1}>Alla</MenuItem>
                        {Timespan.TIMESPAN_STRINGS.map(
                            (timespanString, index) => {
                                return <MenuItem value={index} key={index}>{timespanString}</MenuItem>
                            }
                        )}
                    </Select>
                </FormControl> */}

                <FormControl style={{ marginRight: 16 }}>
                    <InputLabel id="comment-type-form-label">{translation.dictionary["assessments"]}</InputLabel>
                    <Select
                        value={commentTypeFilter}
                        labelId="comment-type-form-label"
                        onChange={handleCommentTypeChange}
                    >
                        <MenuItem value={-1}>{translation.dictionary["all"]}</MenuItem>
                        {CommentType.TYPE_STRINGS.map(
                            (commentTypeString, index) => {
                                return <MenuItem value={index} key={index}>{commentTypeString}</MenuItem>
                            }
                        )}
                    </Select>
                </FormControl>

                <FormControl>
                    <InputLabel id="sortBy-form-label">{translation.dictionary["sort"]}</InputLabel>
                    <Select
                        value={sortBy}
                        labelId="sortBy-form-label"
                        onChange={handleSortChange}
                        style={{ marginRight: 16 }}
                    >
                        <MenuItem value={"newest"}>{translation.dictionary["newestFirst"]}</MenuItem>
                        <MenuItem value={"oldest"}>{translation.dictionary["oldestFirst"]}</MenuItem>
                        {/* <MenuItem value={"priorityHigh"}>Hög prioritet</MenuItem>
                        <MenuItem value={"priorityLow"}>Låg prioritet</MenuItem> */}
                    </Select>
                </FormControl>
                <FormControl>
                {/* <Stack spacing={2}> */}
                    {/* <Typography variant="subtitle1">Tidsintervall</Typography> */}
                    <TextField
                        variant="outlined"
                        label={translation.dictionary["from"]}
                        value={checkboxFilter.SelectedDates.from}
                        type="date"
                        inputProps={{ max: moment(moment.now()).format("YYYY-MM-DD") }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                            store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedDates: { ...checkboxFilter.SelectedDates, from: event.target.value }}});
                        }}
                    />
                    </FormControl>
                    <FormControl>
                        <TextField
                            variant="outlined"
                            label={translation.dictionary["to"]}
                            value={checkboxFilter.SelectedDates.to}
                            type="date"
                            inputProps={{ max: moment(moment.now()).format("YYYY-MM-DD") }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                                store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedDates: { ...checkboxFilter.SelectedDates, to: event.target.value }}});
                            }}
                        />
                    </FormControl>
                {/* </Stack> */}
            </div>
            <Grid container spacing={2}>
                <Grid item xs={2} className={classes.gridItem}>
                    <OrganizationTreeView
                        selected={checkboxFilter.SelectedIds}
                        feedback={(selectedIds: string[]) => {
                            // setSelectedIds(selectedIds.length > 0 ? selectedIds : ["1"]);
                            store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedIds: selectedIds.length > 0 ? selectedIds : ["1"]}});
                            // let selected = units.filter(x => selectedIds.includes(x.unitId));
                            // if (selected.length > 0) {
                            //     selected = selected.concat(getInactiveUnits()); //Include inactive units to get historic data
                            //     setSelectedUnits(selected);
                            // }
                        }}
                    />            
                </Grid>
                <Grid item xs={10}>
                    <ActionList
                        searchString={searchString}
                        categoryFilter={categoryFilter} commentTypeFilter={commentTypeFilter} timespanFilter={timespanFilter} priorityFilter={priorityFilter}
                        sortBy={sortBy}
                        sinceFilter={moment(checkboxFilter.SelectedDates.from).toDate()} 
                        toFilter={moment(checkboxFilter.SelectedDates.to).toDate()}
                        // selectedIds={checkboxFilter.SelectedIds}
                        // selectedUnits={selectedUnits}
                        
                        // sinceFilter={moment(selectedDates.from).toDate()} 
                        // toFilter={moment(selectedDates.to).toDate()}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
export default ActionsView;