import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './redux/storeStates/store';
import { Provider } from 'react-redux';
import DataInit from './data/DataInit';
import Keycloak from 'keycloak-js';

// import reportWebVitals from './reportWebVitals';

// DataInit.Data();
const keycloak: Keycloak.KeycloakInstance = Keycloak();

keycloak.init({ onLoad: 'login-required' })
 .success(() => {
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App keycloak={keycloak}/>
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);
}).error(error => console.log(error));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
