import { ButtonGroup, IconButton, Paper, Stack, Toolbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userColumns } from "../interfaces/ui/Columns";
import { Store } from "../redux/storeStates/StoreStates";
import BasicTable from "./common/BasicTable";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import ConfirmationDialog from "./common/ConfirmationDialog";
import { store } from "../redux/storeStates/store";
import { ActionTypes } from "../redux/actionTypes";
import User from "../interfaces/entities/User";
import Unit from "../interfaces/entities/Unit";
import { FormTypes } from "../interfaces/ui/Enums";
import OrganizationTreeView from "./common/TreeView";
import Routes from "../data/Routes";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      height: "100%",
    },
  });

function getUsers(units: Unit[]) {
    let users: User[] = [];
    units.forEach(unit => {
        users = users.concat(unit.users);
    })

    return users;
}

export default function Users() {
    const classes = useStyles();
    const units = useSelector((state: Store) => state.units);
    const selectedUsers = useSelector((state: Store) => state.selectedUsers);
    const translation = useSelector((state: Store) => state.translation);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>(["1"]);
    const [usersToShow, setUsersToShow] = useState<User[]>([]);

    useEffect(()=>{
        setUsersToShow(getUsers([...units]).filter(user => user.active === 1));
    }, [units])

    return (
        <Stack direction="row" spacing={2} className={classes.root}>
            <Paper elevation={3}>
                <Stack alignItems="center" marginBottom={2}>
                    <Toolbar>
                        <ButtonGroup>
                            <Tooltip title={translation.dictionary["new"]}>
                                <div>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            store.dispatch({
                                                type: ActionTypes.SET_SHOW_FORM, payload: { show: true, type: FormTypes.NEW }
                                            });
                                        }}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title={translation.dictionary["edit"]}>
                                <div>
                                    <IconButton
                                        disabled={!(selectedUsers.length === 1)}
                                        color="primary"
                                        onClick={() => {
                                            store.dispatch({
                                                type: ActionTypes.SET_SHOW_FORM, payload: { show: true, type: FormTypes.EDIT }
                                            })
                                        }}>
                                        <Edit />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title={translation.dictionary["remove"]}>
                                <div>
                                    <IconButton
                                        disabled={!(selectedUsers.length > 0)}
                                        color="primary"
                                        onClick={() => {
                                            setShowConfirmationDialog(true);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </ButtonGroup>
                    </Toolbar>

                    <OrganizationTreeView
                        selected={selectedIds}
                        feedback={(selectedIds: string[]) => {
                            let selected: Unit[] = units.filter(unit => selectedIds.includes(unit.unitId));
                            setSelectedIds(selectedIds);
                            setUsersToShow(getUsers(selected));
                        }}
                    />
                </Stack>
            </Paper>

            <BasicTable columns={userColumns} rows={usersToShow} />

            <ConfirmationDialog
                show={showConfirmationDialog}
                label={store.getState().translation.dictionary["remove"]}
                text={store.getState().translation.dictionary["confirm"]}
                yesButtonLabel={store.getState().translation.dictionary["yes"]}
                noButtonLabel={store.getState().translation.dictionary["no"]}
                onChoice={(choice) => {
                    if (choice) {
                        Routes.User.delete(selectedUsers)
                    }
                    setShowConfirmationDialog(false);
                }}
            />
        </Stack>
    )
}