import axios from "axios";
import AssessmentEventRepository from "./AssessmentEventRepository";
import ConfigurationRepository from "./ConfigurationRepository";
import ImpactFactorRepository from "./ImpactFactorRepository";
import OrganizationRepository from "./OrganizationRepository";
import UnitRepository from "./UnitRepository";
import UserRepository from "./UserRepository";
import DummyOrganizationRepository from "../../utils/dummyRepositories/DummyOrganizationRepository";
import DummyConfigurationRepository from "../../utils/dummyRepositories/DummyConfigurationRepository";
import DummyAssessmentEventRepository from "../../utils/dummyRepositories/DummyAssessmentEventRepository";
import DummyImpactFactorRepository from "../../utils/dummyRepositories/DummyImpactFactorRepository";
import DummyUnitRepository from "../../utils/dummyRepositories/DummyUnitRepository";
import DummyUserRepository from "../../utils/dummyRepositories/DummyUserRepository";
import Config from '../../pweltconfig.json';
import { store } from "../../redux/storeStates/store";
import LanguageRepository from "./LanguageRepository";

interface PweltConfig{
    REACT_APP_API_URL: string;
}

export function getHeaders(){
    return {
        headers: {
            'Authorization': `Bearer ${store.getState().keycloak.token}`,        
        }
       }
}

export function getConfiguration(conf_data:any){
    return {
        headers: {
            'Authorization': `Bearer ${store.getState().keycloak.token}`,        
        },
        data: conf_data,
       }
}

// export function getHeaders(){
//     return {
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json; charset=UTF-8',
//             'Authorization': `Bearer ${store.getState().keycloak.token}`,        
//         }
//        }
// }

// export const api_headers = {
//     'Accept': 'application/json',
//     'Content-Type': 'application/json; charset=UTF-8',
//     'Authorization': 'Bearer ' + store.getState().keycloak != null ? store.getState().keycloak.token : "",
// }

export const api = axios.create({
    // baseURL: "http://localhost:8080/api/v1/"
    // baseURL: (Config as PweltConfig).REACT_APP_API_URL,
    baseURL: `${(Config as PweltConfig).REACT_APP_API_URL}/api/v1/`,
    //headers: api_headers,
})

/**
 * Prod
 */
const DataRepository = {
    Organization: OrganizationRepository,
    Configuration: ConfigurationRepository,
    Unit: UnitRepository,
    User: UserRepository,
    ImpactFactor: ImpactFactorRepository,
    AssessmentEventRepository: AssessmentEventRepository,
    Language: LanguageRepository,
}

/**
 * Dev
 */
// const DataRepository = {
//     Organization: DummyOrganizationRepository,
//     Configuration: DummyConfigurationRepository,
//     Unit: DummyUnitRepository,
//     User: DummyUserRepository,
//     ImpactFactor: DummyImpactFactorRepository,
//     AssessmentEventRepository: DummyAssessmentEventRepository,
// }

export default DataRepository;