import { showSnackbar } from "../../components/common/SnackBar";
import ImpactFactor from "../../interfaces/entities/ImpactFactor";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { api, getHeaders } from "./DataRepository";

export function getAllImpactFactors(): Promise<ImpactFactor[]> {
    return new Promise((resolve, reject) => {
        api.get(`impactFactor/all`, getHeaders())
            .then((res) => {
                resolve(res.data as ImpactFactor[]);
            }).catch(err => {
                console.log(err)
            })
    })
}

const ImpactFactorRepository = {
    getAll: getAllImpactFactors,
}

export default ImpactFactorRepository;