import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, Radio, RadioGroup, Stack, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import OrganizationTreeView from './common/TreeView';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Store } from '../redux/storeStates/StoreStates';
import AssessmentEvent from '../interfaces/entities/AssessmentEvent';
import CheckIcon from '@mui/icons-material/Check';
import Unit from '../interfaces/entities/Unit';
import moment from 'moment';
import User from '../interfaces/entities/User';
import EntityUtils from '../utils/EntityUtils';
import { UserAssessmentEvent } from '../interfaces/entities/UserAssessmentEvent';
import UserAssessment from '../interfaces/entities/UserAssessment';
import { ExportFields } from "../interfaces/ui/Enums";
import ImpactAssessmentValue from '../interfaces/entities/ImpactAssessmentValue';
import { store } from '../redux/storeStates/store';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface IField {
    name: string;
    headerText: string;
    checked: boolean;
}

const Fields: IField[] = [
    /*0*/{ name: ExportFields.assessmentMain, headerText: "Assessment Main", checked: true },
    /*1*/{ name: ExportFields.assessmentMin, headerText: "Assessment Min", checked: true },
    /*2*/{ name: ExportFields.assessmentMax, headerText: "Assessment Max", checked: true },
    /*3*/{ name: ExportFields.prognosisLongterm, headerText: "Prognosis Longterm", checked: true },
    /*4*/{ name: ExportFields.prognosisLongtermComment, headerText: "Prognosis Longterm Comment", checked: true },
    /*5*/{ name: ExportFields.prognosisShortterm, headerText: "Prognosis Shortterm", checked: true },
    /*6*/{ name: ExportFields.prognosisShorttermComment, headerText: "Prognosis Shortterm Comment", checked: true },
    /*7*/{ name: ExportFields.actionsLongterm, headerText: "Actions Longterm", checked: true },
    /*8*/{ name: ExportFields.actionsShortterm, headerText: "Actions Shortterm", checked: true },
    /*9*/{ name: ExportFields.impactFactors, headerText: "Impact Factors", checked: true },
    /*10*/{ name: ExportFields.submissionDate, headerText: "Submissiondate", checked: true },
]

const UserFields: IField[] = [
    /*0*/{ name: ExportFields.assessmentMain, headerText: "Assessment Main", checked: true },
    /*1*/{ name: ExportFields.assessmentMin, headerText: "Assessment Min", checked: true },
    /*2*/{ name: ExportFields.assessmentMax, headerText: "Assessment Max", checked: true },
    /*3*/{ name: ExportFields.prognosisLongterm, headerText: "Prognosis Longterm", checked: true },
    /*4*/{ name: ExportFields.prognosisLongtermComment, headerText: "Prognosis Longterm Comment", checked: true },
    /*5*/{ name: ExportFields.prognosisShortterm, headerText: "Prognosis Shortterm", checked: true },
    /*6*/{ name: ExportFields.prognosisShorttermComment, headerText: "Prognosis Shortterm Comment", checked: true },
    /*9*/{ name: ExportFields.impactFactors, headerText: "Impact Factors", checked: true },
]

function containsObject(obj: any, list: any[]) {
    var x;
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x] === obj) {
            return true;
        }
    }
    return false;
}

function sortFunction(a: any, b: any) {
    var dateA = new Date(a["plannedDate"]).getTime();
    var dateB = new Date(b["plannedDate"]).getTime();
    return dateA > dateB ? -1 : 1;
};

export default function Export() {
    const units = useSelector((state: Store) => state.units);

    const [selectedIds, setSelectedIds] = useState<string[]>([...store.getState().units.map(u=>u.unitId), "1"]);
    const [selectedUnits, setSelectedUnits] = useState<Unit[]>(store.getState().units);
    const [activeStep, setActiveStep] = useState(0);
    const [checkedFields, setCheckedFields] = useState<IField[]>(UserFields);
    const [sortedEvents, setSortedEvents] = useState<AssessmentEvent[]>([]);
    const [selectedEvents, setSelectedEvents] = useState<AssessmentEvent[]>([]);
    const [exportType, setExportType] = useState<string>("USER");
    const [calculationType, setCalculationType] = useState<string>("MODE");
    const translation = useSelector((state: Store) => state.translation);

    const steps = [translation.dictionary["chooseUnits"], translation.dictionary["chooseFields"], translation.dictionary["chooseEvents"]];

    const handleNext = () => {
        if (activeStep === 2) {
            if (exportType === "UNIT")
                ExportAsCSVUnit(selectedUnits, checkedFields, selectedEvents);
            else if (exportType === "USER")
                ExportAsCSVUser(selectedUnits, checkedFields, selectedEvents);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSelected(selectedIds);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let updatedCheckedFields: IField[] = [...checkedFields];
        updatedCheckedFields.forEach(cf => {
            if (cf.name === event.target.name)
                cf.checked = event.target.checked;
        });
        setCheckedFields(updatedCheckedFields);
    };

    const handleDefaultExport = () => {
        if (!(units.length > 0))
            return;

        ExportAsCSVUnit(units, Fields, [units.filter(unit => unit.active === 1)[0].unitAssessmentEvents.filter(x => new Date(x.plannedDate).getTime() < new Date().getTime()).sort(sortFunction)[0]]);
    }

    function getUniqueDateAssessmentEvents(mode: string) {
        let dates:string[] = [];
        let allEvents:AssessmentEvent[] = [];
        if (exportType === "UNIT") {
            units.forEach(un=>{
                un.unitAssessmentEvents.forEach(ae=>{
                    if(!containsObject(ae.plannedDate, dates)){
                        allEvents.push(ae);
                        dates.push(ae.plannedDate);
                    }
                })
            })
        } else if (exportType === "USER") {
            units.forEach(un=>{
                un.users.forEach(u=>{
                    u.userAssessmentEvents.forEach(ae=>{
                        if(!containsObject(ae.plannedDate, dates)){
                            allEvents.push(ae);
                            dates.push(ae.plannedDate);
                        }
                    })
                })
            })
        }
         
        let events = allEvents.filter(x => moment(x.plannedDate).isBefore(moment.now()));
        return events;
    }

    function setSelected(ids: string[]) {
        if (!(units.length > 0))
            return;

        let newSelectedUnits = units.filter(x => ids.includes(x.unitId));

        if (!(newSelectedUnits.length > 0) || !newSelectedUnits[0].unitAssessmentEvents) {
            setSortedEvents([]);
            return;
        }

        if (exportType === "UNIT") {
            setSelectedUnits(newSelectedUnits);
            let events = getUniqueDateAssessmentEvents("UNIT");
            setSortedEvents(events.sort(sortFunction));
            setSelectedEvents([events.sort(sortFunction)[0]]); //Selects the latest event

        } else if (exportType === "USER") {
            let users: User[] = [];
            selectedUnits.map(unit => users = users.concat(unit.users));
            if(users.length>0) {
                let events = getUniqueDateAssessmentEvents("USER");
                setSortedEvents(events.sort(sortFunction));
                setSelectedEvents([events.sort(sortFunction)[0]]); //Selects the latest event    
            }
        }
    }

    const handleTypeChange = (type: string) => {
        setExportType(type);
        console.log(type);
        if(type=="UNIT") {
            setCheckedFields(Fields);
        }
        if(type=="USER") {
            setCheckedFields(UserFields);            
        }
    };

    const handleCalculationChange = (type: string) => {
        setCalculationType(type);
    };

    function ExportAsCSVUser(selectedUnits: Unit[], fields: IField[], assessmentEvents: AssessmentEvent[]) {
        let rows: any[] = [];
        let header: any[] = [translation.dictionary["plannedDate"], translation.dictionary["name"]];
        let first = true;
        let impactFactorIndex = fields.length - 1;
        let pos = 2;
        fields.forEach((field) => {
            if (field.checked) {
                if(field.headerText != "Impact Factors") {
                    header.push(field.headerText);
                }else{
                    impactFactorIndex = pos;
                }
            }
            pos++;
        })
    
        selectedUnits.forEach(unit => {
            assessmentEvents.forEach(ae => {
                // let combinedAssessment: UserAssessment = { ...EntityUtils.defaultUserAssessment };
                let combinedAssessment: UserAssessment = { assessmentMain: 0, prognosisLongterm:0, prognosisLongtermComment:"", prognosisShortterm:0, prognosisShorttermComment:"", userImpactAssessmentValues:[] as ImpactAssessmentValue[]} as UserAssessment;
                let impactAssessmentValues:any = {};
                let prognosisLongterms:number[] = [0,0,0,0,0];
                let prognosisShortterms:number[] = [0,0,0,0,0];
                let hasAssessments = false; 
                let assessmentValues: number[] = [];
                let assessmentCount: number = 0;
                let row: any[] = [moment(new Date(ae.plannedDate)).format("YYYY-MM-DD"), unit.name];
                // For mode
                let mainAssessmentArray:number[] = [0,0,0,0,0,0,0,0,0,0,0];
    
                unit.users.forEach(user => {
                    user.userAssessmentEvents.forEach(uae => {
                        if(uae.status == 1 && ae.plannedDate === uae.plannedDate) {
                            hasAssessments = true;
                            if(uae.userAssessment != null) {
                                assessmentValues.push(uae.userAssessment.assessmentMain);
                                combinedAssessment.assessmentMain += uae.userAssessment.assessmentMain;
                                combinedAssessment.prognosisLongterm += uae.userAssessment.prognosisLongterm;
                                prognosisLongterms[uae.userAssessment.prognosisLongterm + 2] += 1;
                                combinedAssessment.prognosisShortterm += uae.userAssessment.prognosisShortterm;
                                prognosisShortterms[uae.userAssessment.prognosisShortterm + 2] += 1;
                                combinedAssessment.prognosisLongtermComment = combinedAssessment.prognosisLongtermComment == "" ?  uae.userAssessment.prognosisLongtermComment.replace(/[\r\n]/gm, ' ') : combinedAssessment.prognosisLongtermComment + " | " + uae.userAssessment.prognosisLongtermComment.replace(/[\r\n]/gm, ' ');
                                combinedAssessment.prognosisShorttermComment = combinedAssessment.prognosisShorttermComment == "" ?  uae.userAssessment.prognosisShorttermComment.replace(/[\r\n]/gm, ' ') : combinedAssessment.prognosisShorttermComment + " | " + uae.userAssessment.prognosisShorttermComment.replace(/[\r\n]/gm, ' ');
                                mainAssessmentArray[uae.userAssessment.assessmentMain]++;
        
                                if(combinedAssessment.userImpactAssessmentValues.length == 0) {
                                    uae.userAssessment.userImpactAssessmentValues.forEach(iav => {
                                        combinedAssessment.userImpactAssessmentValues.push({impactFactor: iav.impactFactor, value: iav.value, comment: iav.comment} as ImpactAssessmentValue);
                                        impactAssessmentValues[iav.impactFactor.name] = [0,0,0];
                                        impactAssessmentValues[iav.impactFactor.name][iav.value] += 1;
                                    });
                                } else {
                                    for(let i=0; i<combinedAssessment.userImpactAssessmentValues.length;i++) {
                                        let ciav = combinedAssessment.userImpactAssessmentValues[i];
                                        uae.userAssessment.userImpactAssessmentValues.forEach(iav => {
                                            if(iav.impactFactor.name == ciav.impactFactor.name) {
                                                ciav.value += iav.value;
                                                ciav.comment += " | " + iav.comment.replace(/[\r\n]/gm, ' ');
                                                impactAssessmentValues[iav.impactFactor.name][iav.value] +=  1;
                                            }
                                        });
                                    }
                                }                                                       
                                assessmentCount += 1;    
                            }
                        }
                    });
                });
                if(hasAssessments) {
                    fields.forEach((field) => {
                        if (field.checked) {
        
                            switch (field.name) {
                                case ExportFields.assessmentMain:
                                    if(assessmentCount!=0){
                                        if(calculationType=="MEAN") {
                                            row.push(combinedAssessment.assessmentMain / assessmentCount);
                                        } else if(calculationType=="MODE") {
                                            let max = Math.max(...mainAssessmentArray);
                                            row.push(mainAssessmentArray.indexOf(max));
                                        }
                                    } else {
                                        row.push(0);
                                    }
                                    break;
                                case ExportFields.assessmentMin:
                                    if(assessmentCount!=0){
                                        row.push(Math.min(...assessmentValues));
                                    } else {
                                        row.push(0);
                                    }
                                    break;
                                case ExportFields.assessmentMax:
                                    if(assessmentCount!=0){
                                        row.push(Math.max(...assessmentValues));
                                    } else {
                                        row.push(0);
                                    }
                                    break;
                                case ExportFields.prognosisLongterm:
                                    if(assessmentCount!=0){
                                        if(calculationType=="MEAN") {
                                            row.push(combinedAssessment.prognosisLongterm / assessmentCount);
                                        } else if(calculationType=="MODE") {
                                            let max = Math.max(...prognosisLongterms);
                                            row.push(prognosisLongterms.indexOf(max)-2);
                                        }
                                    } else {
                                        row.push(0);
                                    }
                                    break;
                                case ExportFields.prognosisLongtermComment:
                                    row.push(combinedAssessment.prognosisLongtermComment.replace(';', ','));
                                    break;
                                case ExportFields.prognosisShortterm:
                                    if(assessmentCount!=0){
                                        if(calculationType=="MEAN") {
                                            row.push(combinedAssessment.prognosisShortterm / assessmentCount);
                                        } else if(calculationType=="MODE") {
                                            let max = Math.max(...prognosisShortterms);
                                            row.push(prognosisShortterms.indexOf(max)-2);
                                        }
                                    } else {
                                        row.push(0);
                                    }
                                    break;
                                case ExportFields.prognosisShorttermComment:
                                    row.push(combinedAssessment.prognosisShorttermComment.replace(';', ','));
                                    break;
                                case ExportFields.impactFactors:
                                    if(first) {
                                        let step = 0;
                                        combinedAssessment.userImpactAssessmentValues.forEach(iav => {
                                            // temp.push(iav.impactFactor.name);                                
                                            header.splice(impactFactorIndex + step, 0, iav.impactFactor.name); 
                                            step++;
                                            // temp.push(iav.impactFactor.name + " comment");                                
                                            header.splice(impactFactorIndex + step, 0, iav.impactFactor.name + " comment"); 
                                            step++;
                                        }); 
                                        // header.splice(impactFactorIndex, 0, temp); 
                                    }
                                    combinedAssessment.userImpactAssessmentValues.forEach(iav => {
                                        if(calculationType=="MEAN") {
                                            row.push(iav.value/assessmentCount);
                                            row.push(iav.comment.replace(';', ','));
                                        } else if(calculationType=="MODE"){
                                            let max = Math.max(...impactAssessmentValues[iav.impactFactor.name]);
                                            row.push(impactAssessmentValues[iav.impactFactor.name].indexOf(max));
                                            row.push(iav.comment.replace(';', ','));
                                        }
                                    });
                                    break;
                                case ExportFields.submissionDate:
                                    row.push("");
                                    break;
        
                                default:
                                    break;
                            }
                        }
                    })
        
                    if (first) {
                        rows.push(header);
                        first = false;
                    }
                    rows.push(row);
                }
                
            })
        });
        let dateNow: string = moment(moment.now()).format("YYYYMMDD_hhmmss")
        let fileName = translation.dictionary["exportUser"] + dateNow;
    
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map((e: any) => e.join(";")).join("\n");
    
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); // Required for FF
    
        link.click();
    }

    function ExportAsCSVUnit(selectedUnits: Unit[], fields: IField[], assessmentEvents: AssessmentEvent[]) {
        let rows: any[] = [];
        let header: any[] = [translation.dictionary["plannedDate"], translation.dictionary["name"]];
        let first = true;
        let impactFactorIndex = fields.length - 1;
        let pos = 2;
        fields.forEach((field) => {
            if (field.checked) {
                if(field.headerText != "Impact Factors") {
                    header.push(field.headerText);
                }else{
                    impactFactorIndex = pos;
                }
            }
            pos++;
        })
    
        selectedUnits.forEach(unit => {
            unit.unitAssessmentEvents.forEach((ua) => {
                if (ua.status == 1 && assessmentEvents.find(x => x.plannedDate == ua.plannedDate)) {
                    let row: any[] = [moment(new Date(ua.plannedDate)).format("YYYY-MM-DD"), unit.name];
    
                    fields.forEach((field) => {
                        if (field.checked) {
    
                            switch (field.name) {
                                case ExportFields.assessmentMain:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.assessmentMain);
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.assessmentMin:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.assessmentMin);
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.assessmentMax:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.assessmentMax);
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.prognosisLongterm:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.prognosisLongterm);
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.prognosisLongtermComment:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.prognosisLongtermComment.replace(/[\n\r]/gm," ").replace(';', ','));
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.prognosisShortterm:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.prognosisShortterm);
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.prognosisShorttermComment:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.prognosisShorttermComment.replace(/[\n\r]/gm," ").replace(';', ','));
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.actionsLongterm:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.actionsLongterm.replace(/[\n\r]/gm," ").replace(';', ','));
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.actionsShortterm:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.actionsShortterm.replace(/[\n\r]/gm," ").replace(';', ','));
                                    } else {
                                        row.push("");
                                    }
                                    break;
                                case ExportFields.impactFactors:
                                    if(ua.unitAssessment != null) {    
                                        if(first){
                                            let step = 0;
                                            ua.unitAssessment.unitImpactAssessmentValues.forEach(iav => {
    
                                                header.splice(impactFactorIndex + step, 0, iav.impactFactor.name); 
                                                step++;
                                                header.splice(impactFactorIndex + step, 0, iav.impactFactor.name + " comment"); 
                                                step++;
                                            });
                                        }
                                        ua.unitAssessment.unitImpactAssessmentValues.forEach(iav => {
                                            row.push(iav.value);
                                            row.push(iav.comment.replace(/[\n\r]/gm," ").replace(';', ','));
                                        });
                                    } else {
                                        row.push("");
                                    }
                                    // TODO
                                    break;
                                case ExportFields.submissionDate:
                                    if(ua.unitAssessment != null) {
                                        row.push(ua.unitAssessment.submissionDate);
                                    } else {
                                        row.push("");
                                    }
                                    break;
    
                                default:
                                    break;
                            }
                        }
                    })
    
                    if (first) {
                        rows.push(header);
                        first = false;
                    }
                    rows.push(row);
                }
            });
        });
    
        let dateNow: string = moment(moment.now()).format("YYYYMMDD_hhmmss")
        let fileName = translation.dictionary["exportUnit"] + dateNow;
    
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map((e: any) => e.join(";")).join("\n");
    
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); // Required for FF
    
        link.click();
    }
    
    return (
        <>
            <Box sx={{ maxWidth: "100%", minHeight: "400px" }}>
                <Button
                    variant="contained"
                    disabled={activeStep !== 0}
                    onClick={handleDefaultExport}
                    sx={{ mt: -2, mb: 3 }}
                >
                    {translation.dictionary["exportDefault"]}
                </Button>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step) => (
                        <Step key={step}>
                            <StepLabel >
                                {step}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <br />
                {activeStep !== 0 ? <></> :
                    <Grid container spacing={1}>
                        <Grid item xs>

                            <Stack alignItems="center">
                                <OrganizationTreeView
                                    selected={selectedIds}
                                    feedback={(selectedIds: string[]) => {
                                        setSelectedIds(selectedIds);
                                        setSelected(selectedIds);
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs>
                            <RadioGroup value={exportType} onChange={ev => { handleTypeChange(ev.target.value); }}>
                                <FormControlLabel value="USER"  control={<Radio icon={<RadioButtonUncheckedIcon color="action" />} checkedIcon={<RadioButtonCheckedIcon color="action" />} />} label={translation.dictionary["userAssessments"]} />
                                <FormControlLabel value="UNIT" control={<Radio icon={<RadioButtonUncheckedIcon color="action" />} checkedIcon={<RadioButtonCheckedIcon color="action" />} />} label={translation.dictionary["unitAssessments"]} />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs>
                            <RadioGroup value={calculationType} onChange={ev => { handleCalculationChange(ev.target.value); }}>
                                <FormControlLabel value="MODE" control={<Radio icon={<RadioButtonUncheckedIcon color="action" />} checkedIcon={<RadioButtonCheckedIcon color="action" />} />} label={translation.dictionary["typeValue"]} />
                                <FormControlLabel value="MEAN" control={<Radio icon={<RadioButtonUncheckedIcon color="action" />} checkedIcon={<RadioButtonCheckedIcon color="action" />} />} label={translation.dictionary["averageValue"]} />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                }
                {activeStep !== 1 ? <></> :
                    <Grid container spacing={1}>
                        <Grid item xs />
                        <Grid item xs={12} >
                            {exportType=="UNIT" &&
                            <Stack direction="row" justifyContent="center">
                                <FormControl sx={{ m: 1 }} component="fieldset" ></FormControl>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[0].checked} onChange={handleFieldChange} name={ExportFields.assessmentMain} />} label={translation.dictionary["assessmentMain"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[1].checked} onChange={handleFieldChange} name={ExportFields.assessmentMin} />} label={translation.dictionary["assessmentMin"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[2].checked} onChange={handleFieldChange} name={ExportFields.assessmentMax} />} label={translation.dictionary["assessmentMax"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[3].checked} onChange={handleFieldChange} name={ExportFields.prognosisLongterm} />} label={translation.dictionary["prognosisLongterm"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[4].checked} onChange={handleFieldChange} name={ExportFields.prognosisLongtermComment} />} label={translation.dictionary["prognosisLongtermComment"]} />
                                </FormGroup>

                                <FormControl sx={{ m: 1 }} component="fieldset" ></FormControl>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[5].checked} onChange={handleFieldChange} name={ExportFields.prognosisShortterm} />} label={translation.dictionary["prognosisShortterm"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[6].checked} onChange={handleFieldChange} name={ExportFields.prognosisShorttermComment} />} label={translation.dictionary["prognosisShorttermComment"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[7].checked} onChange={handleFieldChange} name={ExportFields.actionsLongterm} />} label={translation.dictionary["actionsLongterm"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[8].checked} onChange={handleFieldChange} name={ExportFields.actionsShortterm} />} label={translation.dictionary["actionsShortterm"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[9].checked} onChange={handleFieldChange} name={ExportFields.impactFactors} />} label={translation.dictionary["impactFactors"]} />
                                </FormGroup>
                                <FormControl sx={{ m: 1 }} component="fieldset" ></FormControl>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[10].checked} onChange={handleFieldChange} name={ExportFields.submissionDate} />} label={translation.dictionary["submissionDate"]} />
                                </FormGroup>
                            </Stack>
                            }
                            {exportType=="USER" &&
                            <Stack direction="row" justifyContent="center">
                                <FormControl sx={{ m: 1 }} component="fieldset" ></FormControl>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[0].checked} onChange={handleFieldChange} name={ExportFields.assessmentMain} />} label={translation.dictionary["assessmentMain"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[1].checked} onChange={handleFieldChange} name={ExportFields.assessmentMin} />} label={translation.dictionary["assessmentMin"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[2].checked} onChange={handleFieldChange} name={ExportFields.assessmentMax} />} label={translation.dictionary["assessmentMax"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[3].checked} onChange={handleFieldChange} name={ExportFields.prognosisLongterm} />} label={translation.dictionary["prognosisLongterm"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[4].checked} onChange={handleFieldChange} name={ExportFields.prognosisLongtermComment} />} label={translation.dictionary["prognosisLongtermComment"]} />
                                </FormGroup>

                                <FormControl sx={{ m: 1 }} component="fieldset" ></FormControl>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[5].checked} onChange={handleFieldChange} name={ExportFields.prognosisShortterm} />} label={translation.dictionary["prognosisShortterm"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[6].checked} onChange={handleFieldChange} name={ExportFields.prognosisShorttermComment} />} label={translation.dictionary["prognosisShorttermComment"]} />
                                    <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkedFields[7].checked} onChange={handleFieldChange} name={ExportFields.impactFactors} />} label={translation.dictionary["impactFactors"]} />
                                </FormGroup>
                            </Stack>
                            }
                        </Grid>
                        <Grid item xs />
                    </Grid>

                }
                {activeStep !== 2 ? <></> :
                    <Grid container spacing={1}>
                        <Grid item xs />
                        <Grid item xs />
                        <Grid item xs >
                            <Stack direction="row" justifyContent="center">
                                {/* <Stack direction="column"> */}
                                <List sx={{
                                    width: '220px',
                                    // maxWidth: 360,
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 300,
                                    '& ul': { padding: 0 },
                                }}
                                >
                                    {sortedEvents?.map((ae) =>
                                        <li>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                key={ae.assessmentEventId}
                                                startIcon={containsObject(ae, selectedEvents) ? <CheckIcon /> : <></>}
                                                onClick={() => {
                                                    if (containsObject(ae, selectedEvents)) {
                                                        setSelectedEvents(selectedEvents.filter(se => se.assessmentEventId !== ae.assessmentEventId));
                                                    } else {
                                                        setSelectedEvents([...selectedEvents, ae]);
                                                    }
                                                }}
                                            >
                                                {ae.plannedDate}
                                            </Button>
                                        </li>
                                    )}
                                </List>
                            </Stack>
                        </Grid>
                    </Grid>
                }
            </Box>

            <Box sx={{ mb: 2 }}>
                <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        {translation.dictionary["return"]}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        {activeStep === steps.length - 1 ? translation.dictionary["exportFile"] : translation.dictionary["continue"]}
                    </Button>
                </div>
            </Box>
        </>
    );
}
