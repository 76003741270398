import { showSnackbar } from "../../components/common/SnackBar";
import AssessmentEvent from "../../interfaces/entities/AssessmentEvent";
import { UnitAssessmentEvent } from "../../interfaces/entities/UnitAssessmentEvent";
import { UserAssessmentEvent } from "../../interfaces/entities/UserAssessmentEvent";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { store } from "../../redux/storeStates/store";
import { api, getHeaders } from "./DataRepository";

export function addUnitAssessmentEvents(events: AssessmentEvent[]): Promise<UnitAssessmentEvent[]> {
    return new Promise((resolve, reject) => {
        api.post(`assessmentEvent/unit/add/organizationId/${store.getState().organization.organizationId}`, events, getHeaders())
            .then((res) => {
                resolve(res.data as UnitAssessmentEvent[]);
                // showSnackbar(AlertSeverity.SUCCESS, "Event tillagd");
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["eventAddFail"]);
            })
    })
}

export function addUserAssessmentEvents(events: AssessmentEvent[]): Promise<UserAssessmentEvent[]> {
    return new Promise((resolve, reject) => {
        api.post(`assessmentEvent/user/add/organizationId/${store.getState().organization.organizationId}`, events, getHeaders())
            .then((res) => {
                resolve(res.data as UserAssessmentEvent[]);
                // showSnackbar(AlertSeverity.SUCCESS, "Event tillagt");
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["eventAddFail"]);
            })
    })
}

export function deleteUnitAssessmentEvent(event: UnitAssessmentEvent) {
    return new Promise((resolve, reject) => {
        api.delete(`assessmentEvent/unit/delete/timestamp/${event.submittedTimeStamp}/plannedDate/${event.plannedDate}/organizationId/${store.getState().organization.organizationId}`, getHeaders())
            .then((res) => {
                resolve(res);
                showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["eventRemoved"]);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["eventRemovedFail"]);
            })
    })
}

export function deleteUserAssessmentEvent(event: UserAssessmentEvent) {
    return new Promise((resolve, reject) => {
        api.delete(`assessmentEvent/user/delete/timestamp/${event.submittedTimeStamp}/plannedDate/${event.plannedDate}/organizationId/${store.getState().organization.organizationId}`, getHeaders())
            .then((res) => {
                resolve(res);
                showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["eventRemoved"]);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["eventRemovedFail"]);
            })
    })
}

export function deleteUnitAssessmentEventSequence(timeStamp: string, organizationId: string) {
    return new Promise((resolve, reject) => {
        api.delete(`assessmentEvent/unit/delete/sequence/timestamp/${timeStamp}/organizationId/${organizationId}`, getHeaders())
            .then((res) => {
                resolve(res);
                showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["sequenceRemoved"]);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["sequenceRemovedFail"]);
            })
    })
}

export function deleteUserAssessmentEventSequence(timeStamp: string, organizationId: string) {
    return new Promise((resolve, reject) => {
        api.delete(`assessmentEvent/user/delete/sequence/timestamp/${timeStamp}/organizationId/${organizationId}`, getHeaders())
            .then((res) => {
                resolve(res);
                showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["sequenceRemoved"]);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["sequenceRemovedFail"]);
            })
    })
}

const AssessmentEventRepository = {
    addUnitEvent: addUnitAssessmentEvents,
    addUserEvent: addUserAssessmentEvents,
    deleteUnitAssessmentEvent: deleteUnitAssessmentEvent,
    deleteUserAssessmentEvent: deleteUserAssessmentEvent,
    deleteUnitAssessmentEventSequence: deleteUnitAssessmentEventSequence,
    deleteUserAssessmentEventSequence: deleteUserAssessmentEventSequence,
}

export default AssessmentEventRepository;
