import { Paper } from "@mui/material";
import moment from "moment";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import Unit from "../../interfaces/entities/Unit";
import { UnitAssessmentEvent } from "../../interfaces/entities/UnitAssessmentEvent";
import { UserAssessmentEvent } from "../../interfaces/entities/UserAssessmentEvent";
import { store } from '../../redux/storeStates/store';
import { DashboardCheckboxFilter } from "../../interfaces/entities/DashboardCheckboxFilter";

interface AssessmenValuesChartProps {
    units: Unit[];
    from: string;
    to: string;
    checkboxFilter: DashboardCheckboxFilter;
}

interface AverageValue {
    name: string;
    min: number;
    max: number;
    main: number;
}

function getAverageAssessmentValues(units: Unit[], from: string, to: string, includeUnits: boolean, includeUsers: boolean, calculateMode: boolean, calculateMean: boolean) {
    if (!(units.length > 0))
        return [{ name: store.getState().translation.dictionary["missingData"], min: 0, max: 0, main: 0 }];

    let avgValues: AverageValue[] = [];
    let dates: string[] = [];
    to = moment(to).add(1, "day").format("YYYY-MM-DD");

    if (includeUnits) {
        let events: UnitAssessmentEvent[] = [];

        if (units.length > 0) {
            units.forEach(unit => {
                if (unit.active === 1)
                    events = events.concat(unit.unitAssessmentEvents.filter(ev => moment(ev.plannedDate).isBetween(from, to) && ev.status === 1));
            });
        }

        events.forEach((ev) => {
            if (!dates.includes(ev.plannedDate))
                dates.push(ev.plannedDate);
        })

        dates = dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        dates.forEach((date) => {
            let mainAssessmentArray:number[] = [0,0,0,0,0,0,0,0,0,0,0];
            let avgMin: number[] = [];
            let avgMax: number[] = [];
            let avgMain: number[] = [];
            let datedEvents = events.filter(ev => ev.plannedDate === date);

            datedEvents.forEach((ev) => {
                if (!ev.unitAssessment)
                    return;

                avgMin.push(ev.unitAssessment.assessmentMin);
                avgMax.push(ev.unitAssessment.assessmentMax);
                avgMain.push(ev.unitAssessment.assessmentMain);
                mainAssessmentArray[ev.unitAssessment.assessmentMain]++;
            })

            let mainValue:number = 0;
            if(calculateMode) {
                let max = Math.max(...mainAssessmentArray);
                mainValue = mainAssessmentArray.indexOf(max);
            } else if(calculateMean) {
                mainValue = parseFloat((avgMain.reduce((a, b) => (a + b)) / avgMain.length).toFixed(2));
            }
            avgValues.push({
                name: moment(date).format("YYYY-MM-DD"),

                //Average min/max
                // min: parseFloat((avgMin.reduce((a, b) => (a + b)) / avgMin.length).toFixed(2)),
                // max: parseFloat((avgMax.reduce((a, b) => (a + b)) / avgMax.length).toFixed(2)),

                //Absolute min/max
                min: parseFloat(Math.min(...avgMin).toFixed(2)),
                max: parseFloat(Math.max(...avgMax).toFixed(2)),

                main: mainValue,
            })
        })

    } else if (includeUsers) {
        let events: UserAssessmentEvent[] = [];

        if (units.length > 0) {
            units.forEach(unit => {
                if (unit.users.length > 0 && unit.active === 1) {
                    unit.users.forEach(user => {
                        if (user.active === 1)
                            events = events.concat(user.userAssessmentEvents.filter(ev => moment(ev.plannedDate).isBetween(from, to) && ev.status === 1));
                    })
                }
            });
        }

        events.forEach((ev) => {
            if (!dates.includes(ev.plannedDate))
                dates.push(ev.plannedDate);
        })

        dates = dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        dates.forEach((date) => {
            let mainAssessmentArray:number[] = [0,0,0,0,0,0,0,0,0,0,0];
            let avgMain: number[] = [];
            let datedEvents = events.filter(ev => ev.plannedDate === date);

            datedEvents.forEach((ev) => {
                if (!ev.userAssessment)
                    return;

                avgMain.push(ev.userAssessment.assessmentMain);
                mainAssessmentArray[ev.userAssessment.assessmentMain]++;
            })

            let mainValue:number = 0;
            if(calculateMode) {
                let max = Math.max(...mainAssessmentArray);
                mainValue = mainAssessmentArray.indexOf(max);
            } else if(calculateMean) {
                mainValue = parseFloat((avgMain.reduce((a, b) => (a + b)) / avgMain.length).toFixed(2));
            }

            avgValues.push({
                name: moment(date).format("YYYY-MM-DD"),
                min: parseFloat(Math.min(...avgMain).toFixed(2)),
                max: parseFloat(Math.max(...avgMain).toFixed(2)),
                main: mainValue,
                // main: parseFloat((avgMain.reduce((a, b) => (a + b)) / avgMain.length).toFixed(2)),
            })
        })
    }
    return avgValues;
}

export function AssessmentValuesChart(props: AssessmenValuesChartProps) {
    return (
        <Paper elevation={3}>
            <ResponsiveContainer width="100%" maxHeight={200} aspect={16 / 9}>
                <LineChart
                    data={getAverageAssessmentValues(props.units, props.from, props.to, props.checkboxFilter.UnitAssessments, props.checkboxFilter.UserAssessments, props.checkboxFilter.Mode, props.checkboxFilter.Mean)}
                    margin={{ top: 20, right: 50, bottom: 10 }}
                // onClick={handleEvent}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 10]} tickCount={11} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="max" stroke="#82ca9d" hide={!props.checkboxFilter.Max} />
                    <Line type="monotone" dataKey="main" stroke="#332288" activeDot={{ r: 8 }} hide={!props.checkboxFilter.Main} />
                    <Line type="monotone" dataKey="min" stroke="#AA4499" hide={!props.checkboxFilter.Min} />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    )
}