import { makeStyles, Toolbar } from "@material-ui/core";
import { theme } from "../common/Theme";
import { ButtonGroup, FormControl, Grid, ListItemIcon, ListItemText, Stack, Tooltip } from "@mui/material";
import { FormControlLabel, Checkbox, Button, InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import ImpactFactor from "../../interfaces/entities/ImpactFactor";
import ImpactFactorConfiguration from "../../interfaces/entities/ImpactFactorConfiguration";
import { Store } from "../../redux/storeStates/StoreStates";
import { Section } from "./Section";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import Configuration from "../../interfaces/entities/Configuration";
import defaultEntities from "../../utils/EntityUtils";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import { store } from "../../redux/storeStates/store";
import { ActionTypes } from "../../redux/actionTypes";
import ConfirmationDialog from "../common/ConfirmationDialog";
import Routes from "../../data/Routes";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const modalStyle = makeStyles({
    root: {
        alignContent: "center",
        margin: "auto",
        marginLeft: "50%",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "300px"
    },
    wrapper: {
        padding: "14px"
    },
    header: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    content: {
        paddingTop: "18px",
        paddingBottom: "18px",
    },
    footer: {
    },
    gridList: {
        display: 'grid',
    }
})

interface Form extends Record<string, any> {
    // id: string;
    // intro: { title: string; description: string };
    // assessments: { title: string; description: string }[];
    // prognosis: { title: string; description: string };
    effect: { title: string; description: string, impactFactorConfigurations: ImpactFactorConfiguration[] };
    // actions: { title: string; description: string };
    // acknowledgment: { title: string; description: string };
}

const initialForm: Form = {
    // id: "0",
    // intro: {
    //     title: "Intro titel",
    //     description: "Intro beskrivning."
    // },
    // assessments: [
    //     { title: "Bedömning 1 titel", description: "Bedömning 1 beskrivning." },
    //     { title: "Bedömning 2 titel", description: "Bedömning 2 beskrivning." },
    // ],
    // prognosis: {
    //     title: "Prognos titel",
    //     description: "Prognos beskrivning."
    // },
    effect: {
        title: store.getState().translation.dictionary["impactTitle"],
        description: store.getState().translation.dictionary["impactDescription"],
        impactFactorConfigurations: []
    },
    // actions: {
    //     title: "Åtgärder titel",
    //     description: "Åtgärder beskrivning."
    // },
    // acknowledgment: {
    //     title: "Kvittens titel",
    //     description: "Kvittens beskrivning."
    // }
}

const reorderImpactFactorConfigurations = (list: ImpactFactorConfiguration[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [moved] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, moved);
    return result;
}

export function FormEditView() {
    const configurations = useSelector((state: Store) => state.configurations);
    const impactFactors = useSelector((state: Store) => state.impactFactors);
    const translation = useSelector((state: Store) => state.translation);
    const classes = modalStyle(theme);

    const [choosenConfig, setChoosenConfig] = useState<Configuration>(configurations.find(x => x.active) || defaultEntities.defaultConfiguration);
    const [newConfig, setNewConfig] = useState<Configuration>({ ...choosenConfig });
    const [currentForm, setCurrentForm] = useState<Form>(initialForm);
    const [currentImpactFactorConfigurations, setCurrentImpactFactorConfigurations] = useState<ImpactFactorConfiguration[]>(choosenConfig.impactFactorConfigurations);
    const [previewMode, setPreviewMode] = useState<boolean>(true);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [selectedTypeIndex, setSelectedTypeIndex] = useState<number>(configurations.find(x => x.active)?.type || defaultEntities.defaultConfiguration.type); // useState<number | ''>('');

    const isPicked = (impactFactor: ImpactFactor) => {
        return currentImpactFactorConfigurations.some(fc => fc.impactFactor === impactFactor);
    }

    const factorConfigurationFromFactor = (impactFactor: ImpactFactor) => {
        return currentImpactFactorConfigurations.find(fc => fc.impactFactor === impactFactor);
    }

    const handleAddRemove = (impactFactorConfiguration: ImpactFactorConfiguration) => {
        if (isPicked(impactFactorConfiguration.impactFactor)) {
            let newCF = currentImpactFactorConfigurations;
            newCF = newCF.filter((fc) => fc.impactFactor !== impactFactorConfiguration.impactFactor)
            setCurrentImpactFactorConfigurations(newCF);
        }
        else {
            setCurrentImpactFactorConfigurations([
                ...currentImpactFactorConfigurations,
                impactFactorConfiguration
            ]);
        }
    }

    const handleUpdate = (impactFactorConfiguration: ImpactFactorConfiguration) => {
        let fc = factorConfigurationFromFactor(impactFactorConfiguration.impactFactor);

        if (!fc)
            return;

        let newImpactFactorConfigurations: ImpactFactorConfiguration[] = [...currentImpactFactorConfigurations];
        for (let i = 0; i < newImpactFactorConfigurations.length; i++) {
            if (newImpactFactorConfigurations[i].impactFactorConfigurationId === fc.impactFactorConfigurationId) {
                newImpactFactorConfigurations[i] = impactFactorConfiguration;
                break;
            }
        }
        setCurrentImpactFactorConfigurations(newImpactFactorConfigurations);
    }

    const handleSectionChanged = (property: string, value: string, section: string, index?: number) => {
        let newForm = { ...currentForm };

        if (section === "assessments" && index !== undefined) {
            let newAssessments = [...newForm.assessments]
            if (property === "title") {
                newAssessments[index].title = value;
            }
            else if (property === "description") {
                newAssessments[index].description = value;
            }
            newForm.assessments = newAssessments;
        }
        else {
            // newForm[section][property] = value;
            let tempConfig = { ...newConfig };
            if (property === "title") {
                tempConfig.name = value;
            }
            // else if (property === "type") {
            //     tempConfig.type = value == "Individ" ? 1 : 0;
            // }
            setNewConfig(tempConfig);
        }

        setCurrentForm(newForm);
    }

    const handleDragEnd = (result: any) => {
        if (!result.destination)
            return;

        const reordered = reorderImpactFactorConfigurations(currentImpactFactorConfigurations, result.source.index, result.destination.index);
        setCurrentImpactFactorConfigurations(reordered);
    }

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let configToSave = { ...newConfig };
        configToSave.impactFactorConfigurations = currentImpactFactorConfigurations;
        configToSave.type = selectedTypeIndex as number;
        // if (configToSave.active) {
        //     let configs: Configuration[] = [...configurations];
        //     configs.forEach((c) => {
        //         if (c.configurationId === configToSave.configurationId && !configToSave.active)
        //             c.active = 1;
        //         else
        //             c.active = 0;
        //     })
        //     store.dispatch({
        //         type: ActionTypes.SET_CONFIGURATIONS,
        //         payload: configs
        //     });
        // }
        configToSave.configurationId === "" ?
            Routes.Configuration.add(configToSave)
            // store.dispatch({
            //     type: ActionTypes.ADD_CONFIGURATIONS,
            //     payload: [configToSave]
            // })
            :
            Routes.Configuration.update(configToSave);
        store.dispatch({
            type: ActionTypes.UPDATE_CONFIGURATIONS,
            payload: [configToSave]
        });

        setChoosenConfig(configToSave);
        setNewConfig(configToSave);
        setPreviewMode(true);
    }

    return (
        <form onSubmit={(event) => submit(event)} >
            <div style={{ width: 800, margin: "auto" }}>
                {!previewMode ? <></> :
                    <ButtonGroup>
                        <Tooltip title={translation.dictionary["new"]}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setNewConfig(defaultEntities.defaultConfiguration);
                                    setCurrentImpactFactorConfigurations([]);
                                    setPreviewMode(false);
                                }}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translation.dictionary["edit"]}>
                            <IconButton
                                color="primary"
                                onClick={() => { setPreviewMode(false); }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translation.dictionary["remove"]}>
                            <IconButton
                                disabled={choosenConfig.active === 1}
                                color="primary"
                                onClick={() => {
                                    setShowConfirmationDialog(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                }
                {!previewMode ? <></> :
                    <FormControl fullWidth>
                        <InputLabel >{translation.dictionary["chooseConfiguration"]}</InputLabel>
                        <Select
                            MenuProps={{
                                MenuListProps: {
                                className: classes.gridList, // Apply styles to the ul element
                                },
                            }}                         
                            value={choosenConfig.configurationId}
                            label={translation.dictionary["chooseConfiguration"]}
                            placeholder={translation.dictionary["chooseConfigurationPlaceholder"]}
                            onChange={(event) => {
                                let config: Configuration = configurations.find(config => config.configurationId === event.target.value) || defaultEntities.defaultConfiguration;
                                setChoosenConfig(config);
                                setNewConfig(config);
                                setCurrentImpactFactorConfigurations(config.impactFactorConfigurations);
                                setPreviewMode(true);
                            }}
                        >
                            {configurations.map((config) => (
                                <MenuItem key={config.configurationId} value={config.configurationId} >
                                    <div style={{ display: "flex", alignItems: "center", margin: "auto", paddingRight: "34px" }}>
                                        <ListItemIcon>
                                            {config.type == 0 ? <GroupsIcon color="action" /> : <PersonIcon color="action" />}
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            {config.active ? <CheckCircleSharpIcon color="action" /> : <></>}
                                        </ListItemIcon>
                                        <ListItemText primary={config.name} />
                                    </div>
                                </MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                }
                <br />
                <br />
                <Box bgcolor="#EEEEEE">
                    {/* <Section name="Intro"
                    title={currentForm.intro.title}
                    description={currentForm.intro.description}
                    editMode={!previewMode}
                    onChange={(p, v) => handleSectionChanged(p, v, "intro")}
                />
                {currentForm.assessments.map((assessment, i) => {
                    return <Section key={i} name={"Bedömning " + (i + 1)}
                        title={assessment.title}
                        description={assessment.description}
                        editMode={!previewMode}
                        onChange={(p, v) => handleSectionChanged(p, v, "assessments", i)}
                    />
                })}
                <Section name="Prognos"
                    title={currentForm.prognosis.title}
                    description={currentForm.prognosis.description}
                    editMode={!previewMode}
                    onChange={(p, v) => handleSectionChanged(p, v, "prognosis")}
                /> */}
                    <Section
                        // name="Påverkan"
                        name=""
                        title={newConfig.name}
                        description={""}
                        editMode={!previewMode}
                        onChange={(p, v) => handleSectionChanged(p, v, "effect")}

                        // Factor specifics
                        impactFactorConfigurations={currentImpactFactorConfigurations}
                        impactFactors={impactFactors}
                        onAddRemove={(fc) => handleAddRemove(fc)}
                        onUpdate={handleUpdate}
                        onDragEnd={handleDragEnd}
                    />
                    {/* <Section name="Åtgärder"
                    title={currentForm.actions.title}
                    description={currentForm.actions.description}
                    editMode={!previewMode}
                    onChange={(p, v) => handleSectionChanged(p, v, "actions")}
                />
                <Section name="Kvittens"
                    title={currentForm.acknowledgment.title}
                    description={currentForm.acknowledgment.description}
                    editMode={!previewMode}
                    onChange={(p, v) => handleSectionChanged(p, v, "acknowledgment")}
                /> */}
                </Box>
            </div>
            {previewMode ? <></> :
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color="action" />} 
                            checkedIcon={<CheckBoxIcon color="action" />}
                            checked={newConfig.active === 1}
                            onChange={(event) => {
                                let tempConfig = { ...newConfig };
                                tempConfig.active = event.target.checked ? 1 : 0;
                                setNewConfig(tempConfig);
                            }}
                        />
                    }
                    label={translation.dictionary["setActive"]}
                />
            }
            <div >
                {previewMode ?
                    <Button variant="contained" color="primary"
                        disabled={choosenConfig.active ? true : false}
                        onClick={() => {
                            // let configs: Configuration[] = [...configurations];
                            // configs.forEach((c) => {
                            //     if (c.configurationId === choosenConfig.configurationId && !choosenConfig.active)
                            //         c.active = 1;
                            //     else
                            //         c.active = 0;
                            // })
                            // store.dispatch({
                            //     type: ActionTypes.SET_CONFIGURATIONS,
                            //     payload: configs
                            // });
                            Routes.Configuration.setActive(choosenConfig);
                        }}
                    >
                        {translation.dictionary["setActive"]}
                    </Button>
                    :
                    <>
                        <div>
                        <FormControl style={{ margin: "10px", minWidth: 200 }}>
                            <InputLabel id="type-menu-label">{translation.dictionary["type"]}</InputLabel>
                            <Select
                                MenuProps={{
                                    MenuListProps: {
                                    className: classes.gridList, // Apply styles to the ul element
                                    },
                                }}                                
                                labelId="type-menu-label"
                                value={selectedTypeIndex}
                                onChange={(e) => setSelectedTypeIndex(Number(e.target.value))}
                                // onChange={(e) => {
                                //         handleSectionChanged("type", e.target.value as string, "");
                                // }}    
                                style={{ marginRight: 16 }}
                                // disabled={props.configurationTypes && props.configurationTypes.length < 1}
                            >
                                {[translation.dictionary["group"], translation.dictionary["user"]].map((type, index) => {
                                    return <MenuItem value={index} key={"typeMenuItem" + index}>{type}</MenuItem>
                                })

                                }
                            </Select>
                        </FormControl>
                        </div>
                        <Button variant="contained" color="primary" type="submit"
                        >
                            {translation.dictionary["save"]}
                        </Button>
                        <Button variant="text" color="primary"
                            onClick={() => {
                                if (!previewMode) {
                                    setNewConfig({ ...choosenConfig });
                                    setCurrentImpactFactorConfigurations(choosenConfig.impactFactorConfigurations);
                                    setPreviewMode(true);
                                }
                            }}
                        >
                            {translation.dictionary["cancel"]}
                        </Button>
                        {/* <FormControlLabel
                        control={<Checkbox checked={previewMode} onChange={(e) => setPreviewMode(!previewMode)} />}
                        label="Förhandsvisning"
                        style={{ margin: 0 }}
                    /> */}
                    </>}
            </div >
            <ConfirmationDialog
                show={showConfirmationDialog}
                label={translation.dictionary["remove"]}
                text={translation.dictionary["confirm"]}
                yesButtonLabel={translation.dictionary["yes"]}
                noButtonLabel={translation.dictionary["no"]}
                onChoice={(choice) => {
                    if (choice) {
                        Routes.Configuration.delete(choosenConfig);
                        setNewConfig(defaultEntities.defaultConfiguration);
                        setCurrentImpactFactorConfigurations([]);
                    }
                    setShowConfirmationDialog(false);
                }}
            />
        </form >
    );
}
export default FormEditView;