import { showSnackbar } from "../../components/common/SnackBar";
import Organization from "../../interfaces/entities/Organization";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { store } from "../../redux/storeStates/store";
import { api, getHeaders } from "./DataRepository";

//TODO - get all organizations without children (for login/admin)

export function getOrganizationById(id: string): Promise<Organization> {
    return new Promise((resolve, reject) => {
        // api.get(`organization/get/${id}?startDate=2022-11-01`, getHeaders())
        api.get(`organization/get/${id}`, getHeaders())
        // api.get(`organization/get/${id}`)
            .then((res) => {
                resolve(res.data as Organization);
            }).catch(err => {
                console.log(err);
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["getOrganizationFail"]);
            })
    })
}

export function getAllOrganizations(): Promise<Organization[]> {
    return new Promise((resolve, reject) => {
        // let org = {organizationId: "8dd40ca5-29a6-4c1a-95b0-da6c10b67cf1", name: "DummyOrganization"} as Organization;
        // resolve([{organizationId: "8dd40ca5-29a6-4c1a-95b0-da6c10b67cf1", name: "DummyOrganization"} as Organization, {organizationId: "abbccca7-29a6-4c1a-95b0-da6c10b67cf1", name: "DummyOrganization2"} as Organization]);
        // TODO Call api
        api.get(`organization/login`, getHeaders())
            .then((res) => {
                resolve(res.data as Organization[]);
            }).catch(err => {
                console.log(err);
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["getOrganizationFail"]);
            })
    })
}

export async function addOrganization(org: Organization) {
    let res = await api.post("organization/add", org, {
        headers: { 'Content-Type': 'application/json' }
    })

}

const OrganizationRepository = {
    getById: getOrganizationById,
    add: addOrganization,
    getAll: getAllOrganizations,
}

export default OrganizationRepository;