import { showSnackbar } from "../../components/common/SnackBar";
import ImpactFactor from "../../interfaces/entities/ImpactFactor";
import { LanguageModel } from "../../interfaces/entities/LanguageModel";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { api, getHeaders } from "./DataRepository";
import { store } from "../../redux/storeStates/store";

export function getAllLanguages(): Promise<LanguageModel[]> {
    return new Promise((resolve, reject) => {
        api.get(`languages/all`, getHeaders())
            .then((res) => {
                resolve(res.data as LanguageModel[]);
            }).catch(err => {
                console.log(err)
            })
    })
}

export function getLanguageByName(name: string): Promise<LanguageModel> {
    return new Promise((resolve, reject) => {
        api.get(`languages/${name}`, getHeaders())
            .then((res) => {
                resolve(res.data as LanguageModel);
            }).catch(err => {
                console.log(err)
            })
    })
}

export function setDefaultLanguage(languageName: string): Promise<string> {
    return new Promise((resolve, reject) => {
        console.log("languageName");
        api.post(`languages/default/${languageName}`, null, getHeaders())
            .then(res => {
                resolve(res.data as string);
                console.log("res.data");
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

const LanguageRepository = {
    getByName: getLanguageByName,
    getAll: getAllLanguages,
    setDefault: setDefaultLanguage, 
}

export default LanguageRepository;