import { showSnackbar } from "../components/common/SnackBar";
import AssessmentEvent from "../interfaces/entities/AssessmentEvent";
import Configuration from "../interfaces/entities/Configuration";
import Unit from "../interfaces/entities/Unit";
import { UnitAssessmentEvent } from "../interfaces/entities/UnitAssessmentEvent";
import User from "../interfaces/entities/User";
import { UserAssessmentEvent } from "../interfaces/entities/UserAssessmentEvent";
import { AlertSeverity } from "../interfaces/ui/Enums";
import { ActionTypes } from "../redux/actionTypes";
import { store } from "../redux/storeStates/store";
import { getUnits } from "./DataInit";
import DataRepository from "./repositories/DataRepository";
import { setDefaultLanguage } from "./repositories/LanguageRepository";
import { getAllOrganizations } from "./repositories/OrganizationRepository";

/**
 * Language
 */
function getLanguageByName(name: string) {
    return DataRepository.Language.getByName(name)
}

function getAllLanguages() {
    return DataRepository.Language.getAll()
}

const LanguageRoutes = {
    getByName: getLanguageByName,
    getAll: getAllLanguages,
    setDefault: setDefaultLanguage,
}

/**
 * Organization
 */
function getOrganizationById(id: string) {
    return DataRepository.Organization.getById(id)
}

const OrganizationRoutes = {
    getById: getOrganizationById,
    getAll: getAllOrganizations,
}

/**
 * ImpactFactor
 */
function getAllImpactFactors() {
    DataRepository.ImpactFactor.getAll()
        .then(res => {
            store.dispatch({ type: ActionTypes.SET_IMPACT_FACTORS, payload: res });
        })
}

const ImpactFactorRoutes = {
    getAll: getAllImpactFactors,
}

/**
 * Configuration
 */
function addConfiguration(configuration: Configuration) {
    DataRepository.Configuration.add(configuration)
        .then(res => {
            // configuration.configurationId = res.configurationId;
            store.dispatch({ type: ActionTypes.ADD_CONFIGURATIONS, payload: [res] });

            if (configuration.active) {
                let configs: Configuration[] = [...store.getState().configurations];
                configs.forEach((c) => {
                    if (c.configurationId === configuration.configurationId && !configuration.active)
                        c.active = 1;
                    else
                        c.active = 0;
                })
                store.dispatch({
                    type: ActionTypes.SET_CONFIGURATIONS,
                    payload: configs
                });
            }

            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["configurationAdd"]);
        })
};

function updateConfiguration(configuration: Configuration) {
    //configuration.impactFactorConfigurations.forEach(i => i.impactFactorConfigurationId = "")
    DataRepository.Configuration.update(configuration)
        .then(res => {
            if (configuration.active) {
                let configs: Configuration[] = [...store.getState().configurations];
                configs.forEach((c) => {
                    if (c.configurationId === configuration.configurationId && !configuration.active)
                        c.active = 1;
                    else
                        c.active = 0;
                })
                store.dispatch({
                    type: ActionTypes.SET_CONFIGURATIONS,
                    payload: configs
                });
            }
            store.dispatch({ type: ActionTypes.UPDATE_CONFIGURATIONS, payload: [res] });
            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["configurationUpdate"]);
        })
}

function setActiveConfiguration(configuration: Configuration) {
    DataRepository.Configuration.setActive(configuration)
        .then(res => {
            store.dispatch({ type: ActionTypes.UPDATE_CONFIGURATIONS, payload: [configuration] });
        })
}

function deleteConfiguration(configuration: Configuration) {
    DataRepository.Configuration.delete(configuration)
        .then(res => {
            store.dispatch({ type: ActionTypes.DELETE_CONFIGURATIONS, payload: [configuration] });
            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["configurationDelete"]);
        })
}

const ConfigurationRoutes = {
    add: addConfiguration,
    update: updateConfiguration,
    setActive: setActiveConfiguration,
    delete: deleteConfiguration,
}

/**
 * Unit
 */
function addUnit(unit: Unit) {
    DataRepository.Unit.add(unit)
        .then(res => {
            unit.unitId = res.unitId;
            store.dispatch({ type: ActionTypes.ADD_UNIT, payload: unit });
            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["unitAdd"]);
        })
};

function updateUnit(unit: Unit) {
    DataRepository.Unit.update(unit)
        .then(res => {
            store.dispatch({ type: ActionTypes.UPDATE_UNITS, payload: [unit] });
            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["unitEdit"]);
        })
}

function deleteUnit(units: Unit[]) {

    //"Unflatten" units array
    units.forEach(unit => units = units.filter(u => u.parentUnit.id !== unit.unitId));

    DataRepository.Unit.delete(units.map(u => u.unitId))
        .then(res => {
            let failedIds = res as String[];
            if (failedIds.length > 0) {
                let failedUnits: Unit[] = store.getState().units.filter(u => failedIds.includes(u.unitId));
                showSnackbar(AlertSeverity.WARNING, store.getState().translation.dictionary["unitsRemovedFail"] + failedUnits.map(u => u.name))

                units = units.filter(u => !failedIds.includes(u.unitId));
            } else
                showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["unitRemoved"]);

            let unitsFlatArray: Unit[] = [];
            getUnits([{ name: "top", units: units }] as Unit[], unitsFlatArray)
            store.dispatch({ type: ActionTypes.DELETE_UNITS, payload: unitsFlatArray });
        })
}

const UnitRoutes = {
    add: addUnit,
    update: updateUnit,
    delete: deleteUnit,
}

/**
 * User
 */
function addUser(user: User) {
    DataRepository.User.add(user)
        .then(res => {
            user.userId = res.userId;
            user.oneTimePassword = res.oneTimePassword;
            console.log("added user:");
            console.log(user);
            store.dispatch({ type: ActionTypes.ADD_USER, payload: user });
            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["userAdd"] + "\n" + store.getState().translation.dictionary["copyPassword"], res.oneTimePassword);
        })
};

function updateUser(user: User) {
    DataRepository.User.update(user)
        .then(res => {
            store.dispatch({ type: ActionTypes.UPDATE_USERS, payload: [user] });
            showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["userEdit"]);
        })
}

function deleteUser(users: User[]) {
    DataRepository.User.delete(users.map(u => u.userId))
        .then(res => {
            let failedIds = res as String[];
            if (failedIds.length > 0) {
                let failedUsers: User[] = store.getState().users.filter(u => failedIds.includes(u.userId));
                showSnackbar(AlertSeverity.WARNING, store.getState().translation.dictionary["usersRemovedFail"] + failedUsers.map(u => u.email))
                users = users.filter(u => !failedIds.includes(u.userId));
            } else
                showSnackbar(AlertSeverity.SUCCESS, store.getState().translation.dictionary["userRemoved"]);

            store.dispatch({ type: ActionTypes.DELETE_USERS, payload: users });
        })
}

const UserRoutes = {
    add: addUser,
    update: updateUser,
    delete: deleteUser,
}

/**
 * AssessmentEvent
 */
function addUnitAssessmentEvents(events: AssessmentEvent[]) {
    DataRepository.AssessmentEventRepository.addUnitEvent(events)
        .then((res) => {
            store.dispatch({
                type: ActionTypes.ADD_UNIT_ASSESSMENT_EVENTS,
                payload: events as UnitAssessmentEvent[]
            })
        })
}
function addUserAssessmentEvents(events: AssessmentEvent[]) {
    DataRepository.AssessmentEventRepository.addUserEvent(events)
        .then(res => {
            store.dispatch({
                type: ActionTypes.ADD_USER_ASSESSMENT_EVENTS,
                payload: events as UserAssessmentEvent[]
            })
        })
}
function deleteUnitAssessmentEvent(event: UnitAssessmentEvent) {
    DataRepository.AssessmentEventRepository.deleteUnitAssessmentEvent(event)
        .then(res => {
            let eventsToDelete: UnitAssessmentEvent[] = [];
            store.getState().units.forEach(unit => {
                eventsToDelete = eventsToDelete.concat(unit.unitAssessmentEvents.filter(ev => (ev.plannedDate === event.plannedDate) && (ev.submittedTimeStamp === event.submittedTimeStamp)));
            })
            store.dispatch({
                type: ActionTypes.DELETE_UNIT_ASSESSMENT_EVENTS,
                payload: eventsToDelete
            })
        })
}
function deleteUserAssessmentEvent(event: UserAssessmentEvent) {
    DataRepository.AssessmentEventRepository.deleteUserAssessmentEvent(event)
        .then(res => {
            let eventsToDelete: UserAssessmentEvent[] = [];
            store.getState().units.forEach(unit => {
                unit.users.forEach(user => {
                    eventsToDelete = eventsToDelete.concat(user.userAssessmentEvents.filter(ev => (ev.plannedDate === event.plannedDate) && (ev.submittedTimeStamp === event.submittedTimeStamp)));
                })
            })
            store.dispatch({
                type: ActionTypes.DELETE_USER_ASSESSMENT_EVENTS,
                payload: eventsToDelete
            })
        })
}
function deleteUnitAssessmentEventSequence(event: UnitAssessmentEvent) {
    DataRepository.AssessmentEventRepository.deleteUnitAssessmentEventSequence(event.submittedTimeStamp, store.getState().organization.organizationId)
        .then(res => {
            let eventsToDelete: UnitAssessmentEvent[] = [];
            store.getState().units.forEach(unit => {
                eventsToDelete = eventsToDelete.concat(unit.unitAssessmentEvents.filter(ev => ev.submittedTimeStamp === event.submittedTimeStamp));
            })
            store.dispatch({
                type: ActionTypes.DELETE_UNIT_ASSESSMENT_EVENTS,
                payload: eventsToDelete
            })
        })
}
function deleteUserAssessmentEventSequence(event: UserAssessmentEvent) {
    DataRepository.AssessmentEventRepository.deleteUserAssessmentEventSequence(event.submittedTimeStamp, store.getState().organization.organizationId)
        .then(res => {
            let eventsToDelete: UserAssessmentEvent[] = [];
            store.getState().units.forEach(unit => {
                unit.users.forEach(user => {
                    eventsToDelete = eventsToDelete.concat(user.userAssessmentEvents.filter(ev => ev.submittedTimeStamp === event.submittedTimeStamp));
                })
            })
            store.dispatch({
                type: ActionTypes.DELETE_USER_ASSESSMENT_EVENTS,
                payload: eventsToDelete
            })
        })
}

const UnitAssessmentEventRoutes = {
    add: addUnitAssessmentEvents,
    delete: deleteUnitAssessmentEvent,
    deleteSequence: deleteUnitAssessmentEventSequence,
}
const UserAssessmentEventRoutes = {
    add: addUserAssessmentEvents,
    delete: deleteUserAssessmentEvent,
    deleteSequence: deleteUserAssessmentEventSequence,
}
const AssessmentEventRoutes = {
    Unit: UnitAssessmentEventRoutes,
    User: UserAssessmentEventRoutes,
}





/**
 * Combined Routes
 */
const Routes = {
    Organization: OrganizationRoutes,
    Configuration: ConfigurationRoutes,
    ImpactFactor: ImpactFactorRoutes,
    Unit: UnitRoutes,
    User: UserRoutes,
    AssessmentEvent: AssessmentEventRoutes,
    Language: LanguageRoutes,
}

export default Routes;