import React from 'react';
import {
    createStyles,
    ThemeProvider,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles';
import {CssBaseline, Hidden, Typography, Link} from '@mui/material';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import { theme } from './common/Theme';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://pwelt.com/">
                PWELT
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

//Navigation width
const drawerWidth = 256;

const styles = createStyles({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        width: "100%",
        height: "100%",
        flex: 1,
        backgroundColor: '#232f3e',

    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
});

export interface PaperbaseProps extends WithStyles<typeof styles> { }

function Paperbase(props: PaperbaseProps) {
    const { classes } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Navigator PaperProps={{ style: { width: drawerWidth } }} />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <main className={classes.main}>
                        <Content />
                    </main>
                    <footer className={classes.footer}>
                        <Copyright />
                    </footer>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default withStyles(styles)(Paperbase);