import { FormControlLabel, FormGroup, Radio } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "../redux/storeStates/StoreStates";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, Stack, TextField, Divider, RadioGroup, Paper, Typography, Grid, FormLabel } from "@mui/material";
import Unit from "../interfaces/entities/Unit";
import OrganizationTreeView from "./common/TreeView";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Statusbar } from "./charts/Statusbar";
import { ImpactFactorRadar } from "./charts/ImpactFactorRadar";
import { AssessmentValuesChart } from "./charts/AssessmentValuesChart";
import { ReportFrequenciesBarChart } from "./charts/ReportFrequenciesBarChart";
import { ImpactFactorStackedAreaChart } from "./charts/ImpactFactorStackedAreaChart";
import LinearProgressBar from "./common/LinearProgressBar";
import PrognosisChart from "./charts/prognosis/PrognosisChart";
import { store } from '../redux/storeStates/store';
import { makeStyles } from '@material-ui/styles';
import { DashboardCheckboxFilter } from "../interfaces/entities/DashboardCheckboxFilter";
import { ActionTypes } from "../redux/actionTypes";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles({
    gridItem: {
      borderRight: '1px solid darkgray',
      padding: '5px',
      backgroundColor: '#f5f5f5',
    },
    gridItemHr: {
        paddingRight: '5px',
        height: '100%', 
        paddingTop: '0px !important',
      },
      headerSetting: {
        height: '100%', 
        backgroundColor: '#f5f5f5',
        marginLeft: '0px',
        marginTop: '0px'
    },
    gridItemCharContainer: {
      height: '100%', 
      padding: '20px !important',
  }
  });

export default function Dashboard() {
    const classes = useStyles();
    const units = useSelector((state: Store) => state.units);

    const [selectedUnits, setSelectedUnits] = useState<Unit[]>([...units]);
    const translation = useSelector((state: Store) => state.translation);

    const checkboxFilter = useSelector((state: Store) => state.dashboardCheckboxFilter);

    function setProps() {
        return ({
            units: selectedUnits,
            from: checkboxFilter.SelectedDates.from,
            to: checkboxFilter.SelectedDates.to, //selectedDates.to,
            includeUnits: checkboxFilter.UnitAssessments,
            includeUsers: checkboxFilter.UserAssessments,
            calculationMode: checkboxFilter.Mode,
        });
    }

    function getInactiveUnits() {
        return units.filter(unit => unit.active === 0);
    }

    return (
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.headerSetting}>
                    <Grid item xs={3}>
                    <RadioGroup row>
                        {/* <FormLabel component="legend">{translation.dictionary["dataFiltering"]}</FormLabel> */}
                        <FormControlLabel control={<Radio color="default" checked={checkboxFilter.UnitAssessments}
                            // onChange={ev => setCheckboxFilter({ ...checkboxFilter, UnitAssessments: ev.target.checked, UserAssessments: !ev.target.checked })} />}
                            onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, UnitAssessments: ev.target.checked, UserAssessments: !ev.target.checked }})} />}
                            label={translation.dictionary["unitAssessments"]} />
                        <FormControlLabel control={<Radio color="default" checked={checkboxFilter.UserAssessments}
                            // onChange={ev => setCheckboxFilter({ ...checkboxFilter, UserAssessments: ev.target.checked, UnitAssessments: !ev.target.checked })} />}
                            onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, UserAssessments: ev.target.checked, UnitAssessments: !ev.target.checked }})} />}
                            label={translation.dictionary["userAssessments"]} />
                    </RadioGroup>
                    </Grid>
                    <Grid item xs={3}>
                        {/* <FormLabel component="legend">{translation.dictionary["dataFiltering"]}</FormLabel> */}
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkboxFilter.Min} onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Min: ev.target.checked }})} />} label={translation.dictionary["min"]} />
                            <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkboxFilter.Max} onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Max: ev.target.checked }})} />} label={translation.dictionary["max"]} />
                            <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkboxFilter.Main} onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Max: ev.target.checked }})} />} label={translation.dictionary["main"]} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                        <RadioGroup row>
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.Mode}
                                // onChange={ev => setCheckboxFilter({ ...checkboxFilter, Mode: ev.target.checked, Mean: !ev.target.checked })} />}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Mode: ev.target.checked, Mean: !ev.target.checked }})} />}
                                label={translation.dictionary["typeValue"]} />
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.Mean}
                                // onChange={ev => setCheckboxFilter({ ...checkboxFilter, Mean: ev.target.checked, Mode: !ev.target.checked })} />}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Mean: ev.target.checked, Mode: !ev.target.checked }})} />}
                                label={translation.dictionary["averageValue"]} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={3}>
                    <TextField
                        variant="outlined"
                        label={translation.dictionary["from"]}
                        //value={selectedDates.from}
                        value={checkboxFilter.SelectedDates.from}
                        type="date"
                        inputProps={{ max: moment(moment.now()).format("YYYY-MM-DD") }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                            store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedDates: { ...checkboxFilter.SelectedDates, from: event.target.value }}});
                            // setSelectedDates({ ...selectedDates, from: event.target.value });
                        }}
                    />
                    <TextField
                        variant="outlined"
                        label={translation.dictionary["to"]}
                        // value={selectedDates.to}
                        value={checkboxFilter.SelectedDates.to}
                        type="date"
                        inputProps={{ max: moment(moment.now()).format("YYYY-MM-DD") }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                            store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedDates: { ...checkboxFilter.SelectedDates, to: event.target.value }}});
                            // setSelectedDates({ ...selectedDates, to: event.target.value });
                        }}
                    />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridItemHr}>
                <hr />
                </Grid>
                <Grid container spacing={2} className={classes.gridItemCharContainer}>
                    <Grid item xs={2} className={classes.gridItem}>
                        <OrganizationTreeView
                                // selected={units.length > 0 ? selectedIds : ["1"]}
                                // selected={selectedIds}
                                selected={checkboxFilter.SelectedIds}
                                feedback={(selectedIds: string[]) => {
                                    // setSelectedIds(selectedIds.length > 0 ? selectedIds : ["1"]);
                                    store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedIds: selectedIds.length > 0 ? selectedIds : ["1"]}});
                                    // setSelectedIds(selectedIds);
                                    let selected = units.filter(x => selectedIds.includes(x.unitId));
                                    if (selected.length > 0) {
                                        selected = selected.concat(getInactiveUnits()); //Include inactive units to get historic data
                                        setSelectedUnits(selected);
                                    }
                                }}
                            />
                    </Grid>
                    <Grid item xs={10} className={classes.gridItem}>
                    {/* <Stack spacing={2}> */}
                        <Statusbar {...setProps()} />

                        <AssessmentValuesChart {...setProps()} checkboxFilter={checkboxFilter} />

                        {/* <ImpactFactorStackedAreaChart {...setProps()} /> */}

                        {/* <PrognosisChart {...setProps()} /> */}

                        <ReportFrequenciesBarChart {...setProps()} />
                        {/* <ImpactFactorRadar {...setProps()} /> */}
                    {/* </Stack> */}
                    </Grid>
                </Grid>
            </Grid>
    )
}