import { GridColDef } from "@material-ui/data-grid";
import { store } from '../../redux/storeStates/store';

export const unitColumns: GridColDef[] = [
    {
        field: 'unitId',
        headerName: "id",
        hide: true,
        width: 100
    },
    {
        field: 'name',
        headerName: "name",
        width: 200,
    },
    {
        field: 'description',
        headerName: "description",
        width: 200,
    },
    {
        field: 'size',
        headerName: "size",
        type: 'number',
        width: 150,
    },
    // {
    //     field: 'workTitle',
    //     headerName: "workTitle",
    //     width: 120,
    // },
    // {
    //     field: 'unitSensor',
    //     headerName: "unitSensor ",
    //     type: 'number',
    //     width: 130,
    // },
];

export const userColumns: GridColDef[] = [
    {
        field: 'userId',
        headerName: store.getState().translation.dictionary["id"],
        hide: true,
        width: 100
    },
    {
        field: 'firstName',
        headerName: store.getState().translation.dictionary["firstName"],
        width: 200,
    },
    {
        field: 'lastName',
        headerName: store.getState().translation.dictionary["lastName"],
        width: 200,
    },
    {
        field: 'email',
        headerName: store.getState().translation.dictionary["email"],
        width: 200,
    },
    {
        field: 'workTitle',
        headerName: store.getState().translation.dictionary["workTitle"],
        width: 120,
    },
    {
        field: 'unitSensor',
        headerName: store.getState().translation.dictionary["unitSensor"],
        type: 'number',
        width: 130,
        valueFormatter: (params) => params.row?.unitSensor? "✔": "",
        // valueFormatter: (params) => params.row?.unitSensor? "JA": "",
    },
    {
        field: 'unit',
        headerName: store.getState().translation.dictionary["unit"],
        width: 200,
        valueFormatter: (params) => params.row?.unit.name,
    },
    {
        field: 'oneTimePassword',
        headerName: store.getState().translation.dictionary["unit"],
        width: 200,
    },
];