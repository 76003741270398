import { showSnackbar } from "../../components/common/SnackBar";
import User from "../../interfaces/entities/User";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { api, getConfiguration, getHeaders } from "./DataRepository";
import { store } from "../../redux/storeStates/store";

export interface responseEntityDeleteRequest {
    data: string[];
    status: number;
    statusText: string;
}

export function addUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
        api.post(`user/add/parentId/${user.unit.id}`, user, getHeaders())
            .then(res => {
                resolve(res.data as User);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
                console.log(err);
            })
    })
}

export function updateUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
        let tempUser = { active: user.active, email: user.email, firstName: user.firstName, lastName: user.lastName, unit: user.unit, unitSensor: user.unitSensor, userId: user.userId, userSecurityId: user.userSecurityId, workTitle: user.workTitle } as User;
        console.log(user);
        api.put(`user/update/parentId/${tempUser.unit.id}`, tempUser, getHeaders())
            .then(res => {
                resolve(res.data as User);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
                console.log(err);
            })
    })
}

export function deleteUsers(ids: string[]) {
    return new Promise((resolve, reject) => {
        // api.delete(`user/delete/multiple`, { data: ids })
        api.put(`user/delete/multiple`, ids, getHeaders())
            .then(res => {
                resolve(res.data as String[]);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
                console.log(err);
            })
    })
}

const UserRepository = {
    add: addUser,
    update: updateUser,
    delete: deleteUsers,
}

export default UserRepository;