import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { AlertProps, createStyles, Slide, Theme } from '@mui/material';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { store } from '../../redux/storeStates/store';
import { ActionTypes } from '../../redux/actionTypes';
import { AlertSeverity } from '../../interfaces/ui/Enums';

interface IProps {
    open?: boolean;
    message: string;
    clipboardMessage?: string;
    severity: AlertSeverity;
    hideTimerMillis?: number;
    onAutoClose?: ((open: boolean) => void);
}

const styles = (theme: Theme) =>
  createStyles({
    snack: {
        // background: "#f1f1f1",
        '&:hover': {
          background: "#f00",
        }
      }  
    });

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function _onClose(clipboardMessage?: string) {
    if(clipboardMessage) {
        navigator.clipboard.writeText(clipboardMessage);
    }
    store.dispatch({
        type: ActionTypes.SET_SHOW_SNACKBAR, payload: { open: false, severity: AlertSeverity.INFO, message: "" }
    })
}


export default function SnackbarAlert(props: IProps) {
    return (
        <Snackbar
            className='SnackBar'
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={props.open? props.open : false}
            message={props.message}
            TransitionComponent={SlideTransition}
            onClose={() => props.onAutoClose ? props.onAutoClose(false) : _onClose()}
            // autoHideDuration={props.hideTimerMillis ? props.hideTimerMillis : 2000}
            onClick={()=>_onClose(props.clipboardMessage ? props.clipboardMessage : undefined)}
        >
            <Alert severity={props.severity} sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>

    );
}

export function showSnackbar(severity: AlertSeverity, msg: string, clipboardMessage?: string) {
    store.dispatch({
        type: ActionTypes.SET_SHOW_SNACKBAR, payload: { open: true, severity: severity, message: msg, clipboardMessage: clipboardMessage }
    })
}