import Unit from "../../interfaces/entities/Unit";
import { store } from "../../redux/storeStates/store";
import { api, getConfiguration, getHeaders } from "./DataRepository";
import { showSnackbar } from "../../components/common/SnackBar";
import { AlertSeverity } from "../../interfaces/ui/Enums";

export interface responseEntityRequest {
    data: any;
    status: number;
    statusText: string;
}

export function addUnit(unit: Unit): Promise<Unit> {
    if (!(unit.parentUnit.id) || unit.parentUnit.id === "0") {
        unit.parentUnit.id = store.getState().organization.organizationId;
    }
    return new Promise((resolve, reject) => {
        api.post(`unit/add/parentId/${unit.parentUnit.id}`, unit, getHeaders())
            .then(res => {
                resolve(res.data as Unit);
            })
            .catch(err => {
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
                console.log(err.response.data);
            })
    })
}

export function updateUnit(unit: Unit): Promise<Unit> {
    //Events or users not neccesary
    unit.unitAssessmentEvents = [];
    unit.users = [];
    return new Promise((resolve, reject) => {
        api.put(`unit/update`, unit, getHeaders())
            .then(res => {
                resolve(res.data as Unit);
            })
            .catch(err => {
                console.log(err);
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

export function deleteUnits(ids: string[]) {
    return new Promise((resolve, reject) => {
        // api.delete(`unit/delete/multiple`, { data: ids })
        // api.put(`unit/delete/multiple`, getConfiguration(ids))
        api.put(`unit/delete/multiple`, ids, getHeaders())
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log(err.response.data);
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["somethingWentWrong"]);
            })
    })
}

const UnitRepository = {
    add: addUnit,
    update: updateUnit,
    delete: deleteUnits,
}

export default UnitRepository;