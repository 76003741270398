import { Accordion, AccordionSummary, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { ResponsiveContainer, BarChart, XAxis, Label, YAxis, Tooltip, Bar, Cell } from "recharts";
import Unit from "../../interfaces/entities/Unit";
import { ChartProps } from "../../interfaces/ui/Interfaces";
import LinearProgressBar from "../common/LinearProgressBar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { UserAssessmentEvent } from "../../interfaces/entities/UserAssessmentEvent";
import { store } from '../../redux/storeStates/store';

interface unitFrequences {
    name:string,
    freq:number
}

export function ReportFrequenciesBarChart(props: ChartProps) {
    const [averageFreq, setAverageFreq] = useState(1);
    const [unitFreqs, setUnitFreqs] = useState([] as unitFrequences[]);

    useEffect(()=>{
        setUnitFreqs(getUnitFrequencies(props.units, props.from, props.to, props.includeUnits, props.includeUsers));
    }, [props]);

    function getUnitFrequencies(units: Unit[], from: string, to: string, includeUnits: boolean, includeUsers: boolean) {
        if (!(units.length > 0))
            return [];

        let reportFrequency: { name: string, freq: number }[] = [];
        let avgFreq: number = 0;
        let perUnitFrequencies: { unit: Unit, freq: number }[] = []
        to = moment(to).add(1, "day").format("YYYY-MM-DD");

        units.map(u => {
            if (u.active)
                perUnitFrequencies.push({ unit: u, freq: 0 });
        })



        if (includeUnits) {
            perUnitFrequencies.forEach(perUnit => {
                let assessmentsWithinTimeFrame = perUnit.unit.unitAssessmentEvents.filter(ev => moment(ev.plannedDate).isBetween(from, to));
                let performedAssessmentsCount = assessmentsWithinTimeFrame.filter(ev => ev.status === 1).length;
                perUnit.freq = !assessmentsWithinTimeFrame || assessmentsWithinTimeFrame.length==0 ? 0.0 : performedAssessmentsCount / assessmentsWithinTimeFrame.length;
            })
        }

        if (includeUsers) {
            perUnitFrequencies.forEach(perUnit => {
                let performedAssessmentsCount: number = 0;
                let assessmentsWithinTimeFrame: UserAssessmentEvent[] = [];
                perUnit.unit.users.forEach(user => {
                    if (user.active) {
                        let currentAssessmentsWithinTimeFrame = user.userAssessmentEvents.filter(ev => moment(ev.plannedDate).isBetween(from, to));
                        assessmentsWithinTimeFrame = assessmentsWithinTimeFrame.concat(currentAssessmentsWithinTimeFrame);
                        performedAssessmentsCount += currentAssessmentsWithinTimeFrame.filter(ev => ev.status === 1).length;
                    }
                })
                // if (includeUnits)
                //     perUnit.freq = (perUnit.freq + (performedAssessmentsCount / assessmentsWithinTimeFrame.length)) / 2;
                // else
                perUnit.freq = !assessmentsWithinTimeFrame || assessmentsWithinTimeFrame.length==0 ? 0.0 : performedAssessmentsCount / assessmentsWithinTimeFrame.length;
            })
        }

        perUnitFrequencies.map(perUnit => reportFrequency.push({ name: perUnit.unit.name, freq: parseFloat((perUnit.freq * 100).toFixed(1)) }));
        reportFrequency.sort((a, b) => a.freq - b.freq);
        avgFreq = reportFrequency.length != 0 ? reportFrequency.map(item => item.freq).reduce((a, b) => a + b) / reportFrequency.length : 0.0;

        if (avgFreq !== averageFreq) setAverageFreq(avgFreq);

        return reportFrequency as unitFrequences[];
    }

    return (
        // <Paper elevation={3}>
        
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack spacing={1} justifyContent="center">
                    <Typography variant="subtitle1" >
                        {store.getState().translation.dictionary["reportFrequency"]}
                    </Typography>
                    <LinearProgressBar progress={averageFreq ? averageFreq : 0} />
                </Stack>
            </AccordionSummary>
            <ResponsiveContainer minHeight="100px" width='100%' height={40 + unitFreqs.length * 15}>
                <BarChart
                    data={unitFreqs}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 50,
                        bottom: 10
                    }}
                    layout="vertical"
                >
                    <XAxis type="number" domain={[0, 100]}>
                    </XAxis>
                    <YAxis dataKey="name" type="category" />
                    <Tooltip />
                    <Bar dataKey="freq" name={store.getState().translation.dictionary["reportFrequency"]} unit="%">
                        {unitFreqs.map((entry, index) => (
                            <Cell fill={'#8884d8'} key={`cell-${index}`} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Accordion>

        // </Paper>
    )
}