import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    show: boolean,
    label: string,
    text: string,
    yesButtonLabel: string,
    noButtonLabel: string,
    onChoice: ((choice: boolean) => void),
}

export default function ConfirmationDialog(props: Props) {
    const yesClick = () => {
        props.onChoice(true);
    }

    const noClick = () => {
        props.onChoice(false);
    }

    return (
        <div>
            <Dialog
                open={props.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.label}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={noClick} color="primary">
                        {props.noButtonLabel}
                    </Button>
                    <Button onClick={yesClick} color="primary" autoFocus>
                        {props.yesButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}