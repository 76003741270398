import React from 'react';
import { AppBar, Avatar, Box, Button, Grid, Hidden, IconButton, Link, Tab, Tabs, Toolbar, Tooltip, Typography, MenuItem, Divider, ListItemIcon } from '@mui/material';
import { Notifications, Help, PersonAdd, Settings, Logout, Menu } from '@mui/icons-material';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store } from '../redux/storeStates/StoreStates';
import { showSnackbar } from './common/SnackBar';
import { AlertSeverity, Views } from '../interfaces/ui/Enums';
import { store } from '../redux/storeStates/store';
import LanguageIcon from '@mui/icons-material/Language';
import AccountMenu from './AccountMenu';


const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme) =>
    createStyles({
        secondaryBar: {
            zIndex: 0,
        },
        menuButton: {
            marginLeft: -theme.spacing(1),
        },
        iconButtonAvatar: {
            padding: 4,
        },
        link: {
            textDecoration: 'none',
            color: lightColor,
            '&:hover': {
                color: theme.palette.common.white,
            },
        },
        button: {
            borderColor: lightColor,
        },
        headerCustom: {
            backgroundColor: '#232f3e',
            color: '#ffffff'
        },
        avatarMenu: {
          float: 'right'
      },      
    });

interface HeaderProps extends WithStyles<typeof styles> {
    onDrawerToggle: () => void;
}



function Header(props: HeaderProps) {
    const { classes, onDrawerToggle } = props;
    const currentView = useSelector((state: Store) => state.currentView);

    let headerLabel = currentView;
    
    return (
        <React.Fragment>         

            <AppBar color='inherit' position="sticky" elevation={0} sx={{ height: 67 }}>
                <Toolbar className={classes.headerCustom}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <Typography color="inherit" variant="h5" component="h1">
                                {store.getState().translation.dictionary[headerLabel]}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                            <AccountMenu />
                            {/* <IconButton
                                color="inherit"
                                className={classes.menuButton}
                                onClick={()=>showSnackbar(AlertSeverity.INFO, store.getState().translation.dictionary["comingSoon"])}
                            >
                                <Menu />
                            </IconButton>
                            <LanguageIcon />
                            <Tooltip title={store.getState().translation.dictionary["users"]}>
                                <IconButton color="inherit" className={classes.iconButtonAvatar}>
                                    <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
                                </IconButton>
                            </Tooltip> */}
                        </Grid>
                    </Grid>
                    
                </Toolbar>
            </AppBar>
            {/* <AppBar
                component="div"
                className={classes.secondaryBar}
                color="primary"
                position="static"
                elevation={0}
            > */}
            {/* <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <Typography color="inherit" variant="h5" component="h1">
                                {headerLabel}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Help">
                                <IconButton color="inherit">
                                    <Help />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar> */}
            {/* </AppBar> */}
    {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu> */}
        </React.Fragment>
    );
}

export default withStyles(styles)(Header);