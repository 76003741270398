import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import { CheckboxProps } from "@mui/material/Checkbox"; 

// a wrapper class for material ui checkbox
// Since you are just using the mui checkbox, simply pass all the props through to restore functionality.

function CheckboxWrapper(props: CheckboxProps) {
    return (
        <Checkbox
            icon={<CheckBoxOutlineBlankIcon color="action" />} 
            checkedIcon={<CheckBoxIcon color="action" />}
            indeterminateIcon={<IndeterminateCheckBox color="action" />}
            {...props}
        />
    );
}

export default CheckboxWrapper;