import { store } from '../../../redux/storeStates/store';

export const LONGTERM        = 0;
export const LONGTERM_STRING = store.getState().translation.dictionary["prognosisLongterm"];
export const SHORTTERM        = 1;
export const SHORTTERM_STRING = store.getState().translation.dictionary["prognosisShortterm"];
export const ACTION_LONGTERM        = 2;
export const ACTION_LONGTERM_STRING = store.getState().translation.dictionary["actionsLongterm"];
export const ACTION_SHORTTERM        = 3;
export const ACTION_SHORTTERM_STRING = store.getState().translation.dictionary["actionsShortterm"];
export const IMPACT_FACTOR        = 4;
export const IMPACT_FACTOR_STRING = store.getState().translation.dictionary["impactFactors"];
export const TYPE_STRINGS: string[] = [LONGTERM_STRING, SHORTTERM_STRING, ACTION_LONGTERM_STRING, ACTION_SHORTTERM_STRING, IMPACT_FACTOR_STRING]